import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";

export const useInstallmentPlanQuery = (installmentPlanId: string) => {
    const backendApiClient = useBackendApiClient()
    return useQuery({
        queryKey: [installmentPlanId],
        queryFn: () =>
            backendApiClient.installmentPlan.getInstallmentPlanById({
                loanId: installmentPlanId!,
            })
    });
};