import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { LoanDocumentRemoveRequest } from "../motalvip-apis/generated";
import toast from "react-hot-toast";

export const useDeleteInstallmentPlanDocumentMutation = (
    installmentPlanId?: string
) => {
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: LoanDocumentRemoveRequest) =>
            backendApiClient.installmentPlan.removeLoanDocument({
                requestBody: req,
            }),
        onSuccess: () => {
            toast.success("บันทึกสำเร็จ");
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        retry: 0,
    });
};