import { Chip } from "@mui/material";
import { ComponentPropsWithoutRef } from "react";
import { InstallmentPaymentDto } from "../../../../client/motalvip-apis/generated";
import { installmentPaymentTypeToTextMap } from "./mappers/installmentPaymentTypeToTextMap";

export function InstallmentPaymentTypeChip({
  paymentType,
  ...rest
}: ComponentPropsWithoutRef<typeof Chip> & {
  paymentType?: InstallmentPaymentDto.type;
}) {
  const text =
    installmentPaymentTypeToTextMap[
      paymentType as InstallmentPaymentDto.type
    ] || "ไม่ได้ระบุ";

  return <Chip label={text} {...rest} />;
}
