import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { useBackendApiClient } from "../../../client/motalvip-apis";
import { InstallmentPlanDocumentDto, InstallmentPlanDto, InstallmentTerm, ProductDto, ShopPaymentChannelDto } from "../../../client/motalvip-apis/generated";
import { usePaymentChannelQuery } from '../../../client/query-client/usePaymentChannelQuery';
import { InstallmentMasterDeviceModelField } from '../../../component/Installment/InstallmentMasterDeviceModelFields';
import { InstallmentPlanDocumentCard } from '../../../component/Installment/InstallmentPlanDocumentCard';
import { InstallmentTermTable } from "../../../component/Installment/InstallmentTermTable";
import { CustomInputLabel } from "../../../component/common/CustomInputLabel";
import { Spin } from "../../../component/common/Spin";
import { useShopContext } from "../../../context/ShopContextProvider";
import { validateThaiId } from "../../../util/thai-id-validator";

interface FileWithPreview {
    file: InstallmentPlanDocumentDto;
    uploaded: boolean;
}

type InstallmentPlanImportScreenProps = {};

export const InstallmentPlanImportScreen = (props: InstallmentPlanImportScreenProps) => {

    const nav = useNavigate();

    const shopCtx = useShopContext()
    const [documents, setDocuments] = useState<FileWithPreview[]>([]);

    const { shopId } = useParams()
    const form = useForm<InstallmentPlanDto>({
        defaultValues: {
            fundSource: InstallmentPlanDto.fundSource.SHOP,
            status: InstallmentPlanDto.status.NORMAL,
            registrationDate: dayjs().format("YYYY-MM-DD"),
            shopId: shopId,
            installmentFrequencyType: InstallmentPlanDto.installmentFrequencyType.MONTH,
            product: {
                usedType: ProductDto.usedType.NEW
            },
            profile: {
                phoneNumbers: []
            },
            downPaymentAmount: 0,
            downPaymentChannelId: undefined,
            documents: documents.map(d => d.file)
        },
        mode: 'all'
    });

    const backendApi = useBackendApiClient();

    const paymentChannelsQuery = usePaymentChannelQuery(shopId || "")
    const calculateInstallmentPayments = useMutation({
        mutationFn: (req: InstallmentPlanDto) => backendApi.installmentPlan.calculateInstallmentPayments({ requestBody: req }),
        retry: 0
    })
    const createInstallmentPlanDocument = useMutation({
        mutationFn: (req: InstallmentPlanDocumentDto) => backendApi.installmentPlan.createInstallmentPlanDocument({ requestBody: req }),
        retry: 0
    })
    const generateCreationTokenMutation = useMutation({
        mutationKey: ["installmentPlans"],
        mutationFn: () => backendApi.token.generateCreationToken(),
        retry: 0
    })
    const createLoanMutation = useMutation({
        mutationKey: ["installmentPlans"],
        mutationFn: (req: InstallmentPlanDto) => backendApi.installmentPlan.createInstallmentPlan({ requestBody: req }),
        retry: 0
    });

    const [previewInstallmentPayments, setPreviewInstallmentPayments] = useState<InstallmentTerm[]>([]);
    const [openInstallmentPreviewModal, setOpenInstallmentPreviewModal] = useState(false);
    const handlePreviewOpen = async () => {
        const isValid = await form.trigger();
        if (isValid) {
            await calculateInstallmentPayments.mutate(form.getValues(), {
                onSuccess: (res) => {
                    setPreviewInstallmentPayments(res.installmentTerms || [])
                    setOpenInstallmentPreviewModal(true);
                }
            })
        }
    }
    const handlePreviewClose = () => {
        setOpenInstallmentPreviewModal(false);
    }

    const onDrop = (files: File[]) => {
        files.forEach(file => {
            createInstallmentPlanDocument.mutate({
                originalName: file.name,
                type: InstallmentPlanDocumentDto.type.OTHERS
            }, {
                onSuccess: async (res) => {
                    if (res.uploadUrl) {
                        setDocuments((prev) => [...prev, { file: res, uploaded: false }])
                        uploadFile(file, res.uploadUrl)
                    }
                },
                onError: (error) => {
                }
            })
        })
    }
    const uploadFile = async (file: File, uploadUrl: string) => {
        try {

            const xhr = new XMLHttpRequest();
            xhr.open('PUT', uploadUrl, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    setDocuments((prev) =>
                        prev.map((f) =>
                            f.file.originalName === file.name ? { ...f, uploaded: true } : f
                        )
                    );
                } else {
                    console.error('Upload failed', xhr.responseText);
                }
            };
            xhr.send(file);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    useEffect(() => {
        form.setValue('documents', documents.map(d => d.file))
    }, [documents, form])

    const watchDownPaymentAmount = form.watch("downPaymentAmount");
    useEffect(() => {
        if (watchDownPaymentAmount === undefined || watchDownPaymentAmount === null || isNaN(watchDownPaymentAmount)) {
            form.setValue('downPaymentChannelId', undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchDownPaymentAmount])

    const onSubmit = () => {
        generateCreationTokenMutation.mutate(undefined, {
            onSuccess: (res) => {
                form.setValue('creationToken', res.token)
                createLoanMutation.mutate(form.getValues(), {
                    onSuccess: (res) => {
                        toast.success('สร้างสินเชื่อสำเร็จ')
                        nav(`/${shopId}/loan/${res.id}`);
                    }
                })
            }
        })
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box p={2}>
                    <Spin spinning={calculateInstallmentPayments.isPending}>
                        <Typography variant="h5" p={2}>{
                            'นำเข้าเคส'
                        }</Typography>
                        <Card sx={{ my: 2, width: { xs: "100%" } }}>
                            <CardContent sx={{ padding: 2, paddingBottom: 1 }}>
                                <Stack direction={{ lg: 'row' }} justifyContent='space-between' gap={2}>
                                    <FormControl fullWidth error={!!form.formState.errors.registrationDate}>
                                        <CustomInputLabel>วันที่ (วัน/เดือน/ปี ค.ศ.) *</CustomInputLabel>
                                        <Controller control={form.control}
                                            name="registrationDate"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <DatePicker {...field}
                                                    format="DD/MM/YYYY"
                                                    value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                    onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                    slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                            )} />
                                        {form.formState.errors.registrationDate && (
                                            <FormHelperText error>กรุณาระบุวันที่นำเข้า</FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>{'หมายเลขเคส'}</CustomInputLabel>
                                        <TextField {...form.register('referenceId', { required: false })} size='small' variant="outlined" />
                                    </FormControl>
                                </Stack>

                            </CardContent>
                        </Card>
                        <Stack direction={{ xs: 'column', lg: 'row' }} gap={2} useFlexGap alignItems='flex-start' >
                            <Card sx={{ width: '100%' }}>
                                <CardContent sx={{ padding: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant="h6">ข้อมูลลูกค้า</Typography>
                                        <Stack direction='row' gap={2}>
                                            <FormControl fullWidth error={!!form.formState.errors.profile?.firstName}>
                                                <CustomInputLabel>ชื่อ *</CustomInputLabel>
                                                <Controller
                                                    control={form.control}
                                                    name="profile.firstName"
                                                    rules={{ required: true }}
                                                    render={({ field }) => (
                                                        <TextField {...field} size='small' variant="outlined" />
                                                    )} />
                                                {form.formState.errors.profile?.firstName && (
                                                    <FormHelperText error>กรุณาระบุชื่อ</FormHelperText>
                                                )}
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>นามสกุล</CustomInputLabel>
                                                <TextField {...form.register('profile.lastName')} size='small' variant="outlined" />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth error={!!form.formState.errors.profile?.citizenId}>
                                                <CustomInputLabel>หมายเลขประจำตัวประชาชน</CustomInputLabel>
                                                <Controller control={form.control}
                                                    rules={{
                                                        pattern: { value: /^[0-9]{13}$/, message: "หมายเลขประจำตัวประชาชนไม่ถูกต้อง" },
                                                        validate: (value) => {
                                                            if (!value && !form.watch('profile.passportNumber')) {
                                                                return 'กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต';
                                                            }
                                                            if (!validateThaiId(value?.toString() || '') && !form.watch('profile.passportNumber')) {
                                                                return 'หมายเลขประจำตัวประชาชนไม่ถูกต้อง';
                                                            }
                                                            return true;
                                                        }
                                                    }}
                                                    name="profile.citizenId" render={({ field }) => (
                                                        <TextField
                                                            value={field.value ? field.value : ''}
                                                            onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                            size='small'
                                                            variant="outlined" />
                                                    )} />
                                                {form.formState.errors.profile?.citizenId && (
                                                    <FormHelperText error>{`${form.formState.errors.profile?.citizenId.message}`}</FormHelperText>
                                                )}
                                            </FormControl>
                                            <FormControl fullWidth error={!!form.formState.errors.profile?.passportNumber}>
                                                <CustomInputLabel>หมายเลขพาสปอร์ต</CustomInputLabel>
                                                <Controller control={form.control}
                                                    rules={{
                                                        pattern: { value: /^[A-Za-z0-9]*$/, message: "หมายเลขพาสปอร์ตไม่ถูกต้อง" },
                                                        validate: (value) => {
                                                            if (!value && !form.watch('profile.citizenId')) {
                                                                return 'กรุณาระบุหมายเลขประจำตัวประชาชนหรือหมายเลขพาสปอร์ต';
                                                            }
                                                            return true;
                                                        }
                                                    }}
                                                    name="profile.passportNumber" render={({ field }) => (
                                                        <TextField
                                                            value={field.value ? field.value : ''}
                                                            onChange={(e) => field.onChange(e.target.value ? e.target.value : NaN)}
                                                            size='small'
                                                            variant="outlined" />
                                                    )} />
                                                {form.formState.errors.profile?.passportNumber && (
                                                    <FormHelperText error>{`${form.formState.errors.profile?.passportNumber.message}`}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Stack>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ที่อยู่ปัจจุบัน</CustomInputLabel>
                                            <TextField {...form.register('profile.currentAddress')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ที่อยู่ตามบัตรประชาชน</CustomInputLabel>
                                            <TextField {...form.register('profile.citizenAddress')} size='small' variant="outlined" />
                                        </FormControl>
                                        <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>อาชีพ</CustomInputLabel>
                                                <TextField {...form.register('profile.occupation')} size='small' variant="outlined" />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>สถานที่ทำงาน</CustomInputLabel>
                                                <TextField {...form.register('profile.workPlace')} size='small' variant="outlined" />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>เบอร์โทรศัพท์</CustomInputLabel>
                                                <TextField {...form.register('profile.phoneNumbers.0.phoneNumber')} size='small' variant="outlined" />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>อีเมล</CustomInputLabel>
                                                <TextField {...form.register('profile.email')} size='small' variant="outlined" />
                                            </FormControl>
                                        </Stack>
                                        <Stack direction={{ lg: 'row' }} gap={2}>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>LINE ID</CustomInputLabel>
                                                <TextField {...form.register('profile.lineId')} size='small' variant="outlined" />
                                            </FormControl>
                                            <FormControl fullWidth>
                                                <CustomInputLabel>Facebook</CustomInputLabel>
                                                <TextField {...form.register('profile.facebookUrl')} size='small' variant="outlined" />
                                            </FormControl>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>

                            <Card sx={{ width: '100%' }}>
                                <CardContent sx={{ padding: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant="h6">ข้อมูลเครื่อง</Typography>
                                        <Controller control={form.control} name="product.usedType" render={({ field }) => (
                                            <RadioGroup {...field} row>
                                                <FormControlLabel value={ProductDto.usedType.NEW} label='สินค้าใหม่' control={<Radio />} />
                                                <FormControlLabel value={ProductDto.usedType.USED} label='สินค้าใช้แล้ว' control={<Radio />} />
                                            </RadioGroup>
                                        )} />
                                        <InstallmentMasterDeviceModelField form={form} />
                                        <FormControl fullWidth>
                                            <CustomInputLabel>รายละเอียด (อุปกรณ์เสริม, อื่นๆ)</CustomInputLabel>
                                            <TextField {...form.register('product.description')} size='small' variant="outlined" />
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.product?.imei}>
                                            <CustomInputLabel>อีมี่ (IMEI) *</CustomInputLabel>
                                            <Controller control={form.control}
                                                name="product.imei"
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <TextField {...form.register('product.imei')} size='small' variant="outlined" />
                                                )} />
                                            {form.formState.errors.product?.imei && (
                                                <FormHelperText error>กรุณาระบุอีมี่</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>Serial Number</CustomInputLabel>
                                            <TextField {...form.register('product.serialNumber')} size='small' variant="outlined" />
                                        </FormControl>
                                        {shopCtx.isOwner &&
                                            <FormControl fullWidth>
                                                <CustomInputLabel>ต้นทุนเครื่อง</CustomInputLabel>
                                                <Controller control={form.control} name="product.purchaseCost" render={({ field }) => (
                                                    <TextField type="number"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            </FormControl>
                                        }
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                        <Card sx={{ my: 2, width: { xs: '100%' } }}>
                            <CardContent sx={{ padding: 2 }}>
                                <Stack gap={2}>
                                    <Typography variant="h6">ข้อมูลสินเชื่อ</Typography>
                                    <Controller control={form.control} name="fundSource" render={({ field }) => (
                                        <RadioGroup row {...field} value={field.value ? field.value : ''} onChange={(e) => e ? field.onChange(e) : field.onChange()}>
                                            <FormControlLabel value={InstallmentPlanDto.fundSource.SHOP} label='อนุมัติสินเชื่อจากร้านค้า' control={<Radio />} />
                                            <FormControlLabel disabled value={InstallmentPlanDto.fundSource.FINANCE} label='ขออนุมัติสินเชื่อจากผู้ให้บริการ (Coming Soon)' control={<Radio />} />
                                        </RadioGroup>
                                    )} />
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth error={!!form.formState.errors.downPaymentAmount}>
                                            <CustomInputLabel>ยอดเงินดาวน์ *</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{ min: 0, required: true, }}
                                                name="downPaymentAmount" render={({ field }) => (
                                                    <TextField
                                                        type="number"
                                                        placeholder="0"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            {form.formState.errors.downPaymentAmount && (
                                                <FormHelperText error>กรุณาระบุยอดเงินดาวน์ให้ถูกต้อง</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.installmentAmount}>
                                            <CustomInputLabel>ยอดเงินผ่อนต่องวด *</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{ min: 1, required: true }}
                                                name="installmentAmount" render={({ field }) => (
                                                    <TextField type="number"
                                                        placeholder="0"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            {form.formState.errors.installmentAmount && (
                                                <FormHelperText error>กรุณาระบุยอดเงินผ่อนต่องวดให้ถูกต้อง</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.installmentCount}>
                                            <CustomInputLabel>จำนวนงวด *</CustomInputLabel>
                                            <Controller
                                                rules={{
                                                    required: "กรุณาระบุจำนวนงวด",
                                                    min: { value: 1, message: "1 - 60 เท่านั้น" },
                                                    max: { value: 60, message: "1 - 60 เท่านั้น" }
                                                }}
                                                control={form.control} name="installmentCount" render={({ field }) => (
                                                    <TextField type="number"
                                                        value={field.value ? field.value : ''}
                                                        onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                        size='small' variant="outlined" />
                                                )} />
                                            {form.formState.errors.installmentCount && (
                                                <FormHelperText error>{form.formState.errors.installmentCount.message}</FormHelperText>
                                            )}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <CustomInputLabel>ราคาเช่าซื้อรวม (คำนวณ)</CustomInputLabel>
                                            <TextField
                                                inputProps={{ readOnly: true }}
                                                type="number"
                                                value={(watchDownPaymentAmount || 0) + ((form.watch("installmentAmount") || 0) * (form.watch('installmentCount') || 0))}
                                                size='small'
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack direction={{ lg: 'row' }} gap={2}>
                                        <FormControl fullWidth error={!!form.formState.errors.downPaymentDate}>
                                            <CustomInputLabel>วันที่จ่ายเงินดาวน์ (วัน/เดือน/ปี ค.ศ.) *</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{ required: true }}
                                                name="downPaymentDate" render={({ field }) => (
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                        onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                        slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                                )} />
                                            {form.formState.errors.downPaymentDate &&
                                                <FormHelperText error>กรุณาระบุวันที่จ่ายเงินดาวน์</FormHelperText>}
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.installmentStartDate}>
                                            <CustomInputLabel>วันที่เริ่มผ่อนงวดแรก (วัน/เดือน/ปี ค.ศ.) *</CustomInputLabel>
                                            <Controller control={form.control}
                                                rules={{ required: true }}
                                                name="installmentStartDate" render={({ field }) => (
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                        onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                        slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                                )} />
                                            {form.formState.errors.installmentStartDate &&
                                                <FormHelperText error>กรุณาระบุวันที่เริ่มผ่อนงวดแรก</FormHelperText>}
                                        </FormControl>
                                        <FormControl fullWidth error={!!form.formState.errors.installmentFrequency}>
                                            <CustomInputLabel>ระยะห่างเวลาชำระแต่ละงวด (ทุกๆ) *</CustomInputLabel>
                                            <Stack direction='row' gap={1}>
                                                <Stack direction='column' gap={1}>
                                                    <Controller
                                                        rules={{
                                                            required: 'กรุณาระบุ',
                                                            min: { value: 1, message: form.watch('installmentFrequencyType') === InstallmentPlanDto.installmentFrequencyType.MONTH ? '1 เดือนเท่านั้น' : '1-31 วันเท่านั้น' },
                                                            max: {
                                                                value: form.watch('installmentFrequencyType') === InstallmentPlanDto.installmentFrequencyType.MONTH ? 1 : 31,
                                                                message: form.watch('installmentFrequencyType') === InstallmentPlanDto.installmentFrequencyType.MONTH ? '1 เดือนเท่านั้น' : '1-31 วันเท่านั้น'
                                                            }
                                                        }}
                                                        control={form.control} name="installmentFrequency" render={({ field }) => (
                                                            <TextField type="number"
                                                                value={field.value ? field.value : ''}
                                                                onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                                size='small' variant="outlined" />
                                                        )} />

                                                </Stack>
                                                <Controller control={form.control} name="installmentFrequencyType" render={({ field }) => (
                                                    <Select
                                                        id="demo-simple-select-helper"
                                                        {...field}
                                                        size='small'
                                                    >
                                                        <MenuItem value={InstallmentPlanDto.installmentFrequencyType.MONTH}>เดือน</MenuItem>
                                                        <MenuItem value={InstallmentPlanDto.installmentFrequencyType.DAY}>วัน</MenuItem>
                                                    </Select>
                                                )} />
                                                <Button fullWidth variant="outlined" color="primary" onClick={handlePreviewOpen}>
                                                    ตารางการผ่อนชำระ
                                                </Button>
                                            </Stack>
                                            {form.formState.errors.installmentFrequency && (
                                                <FormHelperText error>{form.formState.errors.installmentFrequency.message}</FormHelperText>
                                            )}
                                        </FormControl>

                                    </Stack>
                                    <Stack direction='row' gap={2}>
                                        {shopCtx.isOwner &&
                                            <>
                                                <FormControl sx={{ width: { xs: '100%', lg: '20%' } }}>
                                                    <CustomInputLabel>{`ค่าคอม (ไม่จำเป็น)`}</CustomInputLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name="commissionAmount"
                                                        render={({ field }) => (
                                                            <TextField type="number"
                                                                value={field.value ? field.value : ''}
                                                                onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                                size='small' variant="outlined" />
                                                        )} />
                                                </FormControl>
                                                <FormControl sx={{ width: { xs: '100%', lg: '20%' } }}>
                                                    <CustomInputLabel>{`ค่าใช้จ่ายอื่นๆ (ไม่จำเป็น)`}</CustomInputLabel>
                                                    <Controller
                                                        control={form.control}
                                                        name="otherCostAmount"
                                                        render={({ field }) => (
                                                            <TextField type="number"
                                                                value={field.value ? field.value : ''}
                                                                onChange={(e) => field.onChange(e.target.value ? parseInt(e.target.value) : NaN)}
                                                                size='small' variant="outlined" />
                                                        )} />
                                                </FormControl>
                                            </>
                                        }
                                        {watchDownPaymentAmount > 0 && <FormControl
                                            sx={{ width: { xs: '100%', lg: '25%' } }}
                                            error={form.formState.errors.downPaymentChannelId?.message ? true : false}
                                        >
                                            <CustomInputLabel>ช่องทางการรับเงินดาวน์</CustomInputLabel>
                                            <Controller
                                                control={form.control}
                                                name="downPaymentChannelId"
                                                rules={{
                                                    required: {
                                                        value: watchDownPaymentAmount > 0,
                                                        message: "ต้องการช่องทางรับเงิน"
                                                    }
                                                }}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            labelId="paymentChannel-label"
                                                            id="paymentChannel"
                                                            size="small"
                                                            value={watchDownPaymentAmount > 0 ? field.value : undefined}

                                                        >
                                                            {paymentChannelsQuery.data?.map((p) => (
                                                                <MenuItem key={p.id} value={`${p.id}`}>
                                                                    {
                                                                        p.type === ShopPaymentChannelDto.type.BANK_ACCOUNT
                                                                            ? `${p.bankCode} : ${p.accountId} : ${p.accountName}`
                                                                            : p.type === ShopPaymentChannelDto.type.CASH
                                                                                ? 'เงินสด'
                                                                                : p.type === ShopPaymentChannelDto.type.PROMPT_PAY_QR
                                                                                    ? `คิวอาร์ : ${p.accountName}`
                                                                                    : p.type === ShopPaymentChannelDto.type.PROMPT_PAY
                                                                                        ? `พ้อมเพย์ : ${p.accountId} : ${p.accountName}`
                                                                                        : ''
                                                                    }
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    );
                                                }}
                                            />
                                            {form.formState.errors.downPaymentChannelId && (
                                                <Typography color="error">
                                                    {form.formState.errors.downPaymentChannelId.message}
                                                </Typography>
                                            )}
                                        </FormControl>
                                        }
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Stack direction='row' gap={2} useFlexGap flexWrap="wrap" >
                            <Card sx={{ width: { xs: '100%' } }}>
                                <CardContent sx={{ padding: 2 }}>
                                    <Stack gap={2}>
                                        <Typography variant="h6">ไฟล์แนบ</Typography>
                                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                                            <Box
                                                {...getRootProps()}
                                                sx={{
                                                    width: '100%',
                                                    border: '2px dashed darkgray',
                                                    borderRadius: '8px',
                                                    backgroundColor: 'white',
                                                    padding: 4,
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: '#b3e5fc',
                                                        color: '#03a9f4',
                                                        borderColor: '#03a9f4',
                                                    },
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    height: '120px'
                                                }}
                                            >
                                                <input {...getInputProps()} />
                                                <Stack direction='column' alignItems='center' gap={1}>
                                                    <FileUploadIcon />
                                                    <Typography>อัพโหลดไฟล์</Typography>
                                                </Stack>
                                            </Box>

                                            <Box sx={{ width: '100%', }}>
                                                <Stack sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }} direction={'row'} gap={2}>
                                                    {documents.map(doc =>
                                                        <InstallmentPlanDocumentCard
                                                            document={doc.file}
                                                            uploaded={doc.uploaded}
                                                            onDelete={() => { }}
                                                            onDownload={() => { }} />
                                                    )}</Stack>
                                            </Box>

                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                        <Box sx={{ p: 2, mb: 4, width: { xs: '100%' } }} >
                            <Stack direction='row' gap={1} justifyContent='center'>
                                <Button variant='outlined' size='large'
                                    onClick={() => nav(`/${shopId}/loan`)}
                                    sx={{
                                        width: {
                                            xs: '100%', sm: '100%', md: 200
                                        },
                                    }}>ยกเลิก</Button>
                                <Button variant='contained' size='large'
                                    onClick={onSubmit}
                                    disabled={!form.formState.isDirty || !form.formState.isValid}
                                    sx={{
                                        width: {
                                            xs: '100%', sm: '100%', md: 200
                                        },
                                    }}>บันทึก</Button>
                            </Stack>
                        </Box>
                    </Spin>
                </Box>
                <Dialog open={openInstallmentPreviewModal} onClose={handlePreviewClose} fullWidth maxWidth="md">
                    <DialogTitle>ตารางการผ่อนชำระ</DialogTitle>
                    <DialogContent>
                        <InstallmentTermTable data={previewInstallmentPayments} showPayment={false} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handlePreviewClose} color="primary">
                            ปิด
                        </Button>
                    </DialogActions>
                </Dialog>
            </LocalizationProvider >

        </>
    );
};
