import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { ShopDashboardRequest } from "../motalvip-apis/generated";

export const useShopDashboardQuery = (
    shopId: string,
    req: ShopDashboardRequest
) => {
    const motalClient2 = useBackendApiClient();
    return useQuery({
        queryKey: [shopId],
        queryFn: () =>
            motalClient2.report.getShopDashboard({ shopId: shopId, requestBody: req }),
    });
};