import { createContext, ReactNode, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useShopQuery } from "../client/query-client";
import { dayDiff } from "../util/DateUtil";

import { TableChartOutlined } from "@mui/icons-material";
import ConstructionIcon from "@mui/icons-material/ConstructionOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PriceCheckIcon from "@mui/icons-material/PriceCheckOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import { ShopDto } from "../client/motalvip-apis/generated";
import { Spin } from "../component/common/Spin";
// import { ShopResponse } from "../client/motal-apis/generated";

type ShopContextType = {
  shopId?: string;
  shop?: ShopDto;
  selectedMenu?: string;
  setSelectedMenu: (menuId: string) => void;
  selectedSubMenu?: string;
  setSelectedSubMenu: (menuId: string) => void;
  remainingDays: number;
  isOwner: boolean;
  menuData?: {
    key: string;
    label: string;
    icon: ReactNode;
    isRequiredOwner: boolean;
    children?: {
      key: string;
      label: string;
      isRequiredOwner: boolean;
    }[];
  }[];
  shopMenuData?: {
    key: string;
    label: string;
    icon: ReactNode;
    isRequiredOwner: boolean;
    children?: {
      key: string;
      label: string;
      isRequiredOwner: boolean;
    }[];
  }[];
};

const ShopContext = createContext<ShopContextType>({
  setSelectedMenu: () => {},
  setSelectedSubMenu: () => {},
  remainingDays: 0,
  isOwner: false,
});

type ShopContextProviderProps = {
  children: ReactNode;
};

export const ShopContextProvider = ({ children }: ShopContextProviderProps) => {
  const menuData = [
    {
      key: "/:shopId/dashboard",
      label: "แดชบอร์ด",
      icon: <InsertChartOutlinedOutlinedIcon />,
      isRequiredOwner: true,
    },
    {
      key: "/:shopId/loan",
      label: "สินเชื่อ",
      icon: <CreditScoreOutlinedIcon />,
      isRequiredOwner: false,
      children: [
        {
          key: "/:shopId/loan-calculator",
          label: "เครื่องมือคำนวณ",
          isRequiredOwner: false,
        },
        // {
        //     key: '/:shopId/loan/create-list',
        //     label: 'รายการสมัคร',
        //     isRequiredOwner: false,
        // },
        // {
        //     key: '/:shopId/loan/approval-list',
        //     label: 'รายการอนุมัติ',
        //     isRequiredOwner: true,
        // },
        {
          key: "/:shopId/loan",
          label: "รายการติดตามเคส",
          isRequiredOwner: true,
        },
      ],
    },
    {
      key: "/:shopId/installment-payments-view",
      label: "รายการชำระเงิน",
      icon: <TableChartOutlined />,
      isRequiredOwner: false,
    },
    // {
    //   key: "/:shopId/payments-view",
    //   label: "รายการชำระเงิน",
    //   icon: <TableChartOutlined />,
    //   isRequiredOwner: false,
    // },
    {
      key: "/:shopId/blacklist",
      label: "ตรวจสอบแบล็คลิส",
      icon: <PersonSearchOutlinedIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/coming-soon",
      label: "จำนำ (กำลังพัฒนา)",
      icon: <PriceCheckIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/coming-soon",
      label: "เคลมซ่อม (กำลังพัฒนา)",
      icon: <ConstructionIcon />,
      isRequiredOwner: false,
    },
    {
      key: "/:shopId/coming-soon",
      label: "POS (กำลังพัฒนา)",
      icon: <StorefrontOutlinedIcon />,
      isRequiredOwner: false,
    },
  ];
  const shopMenuData = [
    {
      key: "/settings/shop",
      label: "ร้านค้า",
      icon: <StoreOutlinedIcon />,
      isRequiredOwner: true,
      children: [
        {
          key: "/:shopId/settings/shop",
          label: "ข้อมูลหลัก",
          isRequiredOwner: true,
        },
        {
          key: "/:shopId/settings/payment-channel",
          label: "ช่องทางการจ่ายเงิน",
          isRequiredOwner: true,
        },
      ],
    },
    {
      key: "/:shopId/settings/user",
      label: "ผู้ใช้งาน",
      icon: <PeopleOutlinedIcon />,
      isRequiredOwner: true,
    },
  ];

  const location = useLocation();
  const { shopId } = useParams();
  const [selectedMenu, setSelectedMenu] = useState<string>(
    menuData.find(
      (menu) =>
        (menu.key &&
          shopId &&
          menu.key.replace(":shopId", shopId) === location.pathname) ||
        (menu.children &&
          menu.children.find(
            (sub) =>
              sub.key &&
              shopId &&
              sub.key.replace(":shopId", shopId) === location.pathname
          ))
    )?.key ||
      shopMenuData.find(
        (menu) =>
          (menu.key &&
            shopId &&
            menu.key.replace(":shopId", shopId) === location.pathname) ||
          (menu.children &&
            menu.children.find(
              (sub) =>
                sub.key &&
                shopId &&
                sub.key.replace(":shopId", shopId) === location.pathname
            ))
      )?.key ||
      ""
  );
  const [selectedSubMenu, setSelectedSubMenu] = useState<string>(
    menuData
      .find((menu) => menu.children)
      ?.children?.find(
        (sub) =>
          sub.key &&
          shopId &&
          sub.key.replace(":shopId", shopId) === location.pathname
      )?.key ||
      shopMenuData
        .find((menu) => menu.children)
        ?.children?.find(
          (sub) =>
            sub.key &&
            shopId &&
            sub.key.replace(":shopId", shopId) === location.pathname
        )?.key ||
      ""
  );
  const shopQuery = useShopQuery(shopId || "");
  const currentDate = new Date();

  const shop = shopQuery.data;
  const isOwner = shop?.shopUserRole === ShopDto.shopUserRole.OWNER;
  const remainingDays = shop?.expireAt
    ? dayDiff(new Date(shop.expireAt), currentDate)
    : 0;
  const availableMenus = menuData.filter((e) =>
    e.isRequiredOwner ? isOwner : true
  );
  const availableShopMenus = shopMenuData.filter((e) =>
    e.isRequiredOwner ? isOwner : true
  );

  return (
    <ShopContext.Provider
      value={{
        shopId,
        shop,
        selectedMenu,
        setSelectedMenu,
        selectedSubMenu,
        setSelectedSubMenu,
        remainingDays,
        menuData: availableMenus,
        shopMenuData: availableShopMenus,
        isOwner,
      }}
    >
      <Spin spinning={shopQuery.isLoading}>{children}</Spin>
    </ShopContext.Provider>
  );
};

export const useShopContext = () => useContext(ShopContext);
