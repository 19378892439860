import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { QueryKey } from ".";

export const usePaymentChannelQuery = (shopId: string) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [QueryKey.SHOPS, shopId, "PaymentChannel"],
        queryFn: () => apiClient.shop.getShopPaymentChannels({ shopId }),
    });
};
