import { keyframes } from "@emotion/react";

export const animations = {
  rotate360: keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
    `,
};
