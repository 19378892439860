import { useMutation } from "@tanstack/react-query";
import { QueryKey } from ".";
import { useBackendApiClient } from "../motalvip-apis";
import { BackendApiClient } from "../motalvip-apis/generated";

export const useGenerateInstallmentPaymentCsvMutation = () => {
  const apiClient = useBackendApiClient();
  return useMutation({
    mutationKey: [QueryKey.LOANS, "generateCsvReport"],
    mutationFn: (
      payload: Parameters<
        BackendApiClient["installmentPayment"]["generateCsvReport"]
      >[0]
    ) => apiClient.installmentPayment.generateCsvReport(payload),
  });
};
