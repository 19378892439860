/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentPaymentReportDto = {
    id: string;
    loanId: string;
    shopId: string;
    transactionAt: string;
    status?: InstallmentPaymentReportDto.status;
    loanReferenceId?: string;
    customerName: string;
    customerLastName: string;
    customerEmail?: string;
    customerPassportNumber?: string;
    customerCitizenId?: string;
    paidDueAmount: number;
    paidFineAmount: number;
    installmentTermNumber?: number;
    dateDiffFromDueDate?: number;
    type?: InstallmentPaymentReportDto.type;
    shopPaymentChannel?: InstallmentPaymentReportDto.shopPaymentChannel;
    bankCode?: InstallmentPaymentReportDto.bankCode;
    createdByUserId?: string;
    createdByName?: string;
    createdByLastName?: string;
};
export namespace InstallmentPaymentReportDto {
    export enum status {
        SUCCESS = 'SUCCESS',
        CANCEL = 'CANCEL',
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        OVERDUE = 'OVERDUE',
    }
    export enum type {
        DOWN_PAYMENT = 'DOWN_PAYMENT',
        TERM_PAYMENT = 'TERM_PAYMENT',
        REPAYMENT_PARTIAL = 'REPAYMENT_PARTIAL',
        REPAYMENT_FULL = 'REPAYMENT_FULL',
        FINE = 'FINE',
        APPOINTMENT = 'APPOINTMENT',
        CLOSED = 'CLOSED',
    }
    export enum shopPaymentChannel {
        CASH = 'CASH',
        PROMPT_PAY = 'PROMPT_PAY',
        PROMPT_PAY_QR = 'PROMPT_PAY_QR',
        BANK_ACCOUNT = 'BANK_ACCOUNT',
        AUTOMATIC_QR = 'AUTOMATIC_QR',
    }
    export enum bankCode {
        BBL = 'BBL',
        BAY = 'BAY',
        SCB = 'SCB',
        KBANK = 'KBANK',
        KTB = 'KTB',
        TTB = 'TTB',
    }
}

