import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";
import { useMemo, useState } from "react";
import { InstallmentPaymentDto } from "../../client/motalvip-apis/generated";
import { useShopContext } from "../../context/ShopContextProvider";
import { formatOptionalFullName } from "../../util/NameUtil";
import { PaymentTypeChip } from "../StatusChip/PaymentTypeChip";
import { InstallmentPaymentDeleteDialog } from "./InstallmentPaymentDeleteDialog";

type Props = {
  data: Array<InstallmentPaymentDto> | undefined;
  lastestPaymentId?: string;
};

export const PaymentTable = (props: Props) => {
  const currencyFormat = Intl.NumberFormat("en-Us", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "transactionAt", desc: false },
  ]);
  const shopCtx = useShopContext();
  const [open, setOpen] = useState<{ [id: string]: boolean }>({});

  const openDeleteDialog = (id: string) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: true,
    }));
  };
  const closeDeleteDialog = (id: string) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [id]: false,
    }));
  };

  const columns = useMemo<MRT_ColumnDef<InstallmentPaymentDto>[]>(
    () => [
      {
        id: "transactionAt",
        accessorFn: (row: InstallmentPaymentDto) => {
          return (
            <Typography>
              {dayjs(row.transactionAt).locale("th").format("DD MMM YYYY")}
            </Typography>
          );
        },
        header: "วันที่",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <Typography noWrap>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        id: "dateDiff",
        accessorFn: (row: InstallmentPaymentDto) => {
          return (
            <Typography
              color={
                (row?.dateDiffFromDueDate || 0) >= 0 ? "#43a047" : "#f44336"
              }
            >
              {row?.dateDiffFromDueDate
                ? row.dateDiffFromDueDate === 0
                  ? `ตรงกำหนด`
                  : (row.dateDiffFromDueDate || 0) > 0
                  ? `ก่อน ${row?.dateDiffFromDueDate} วัน`
                  : `เกิน ${-(row?.dateDiffFromDueDate || 0)} วัน`
                : "-"}
            </Typography>
          );
        },
        header: "เทียบกำหนดจ่าย",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box>
            <Typography noWrap>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        id: "type",
        accessorFn: (row: InstallmentPaymentDto) => (
          <PaymentTypeChip type={row.type!} />
        ),
        header: "ประเภท",
        enableHiding: false,
        enablePinning: false,
        grow: false,
        enableSorting: false,
        size: 100,
      },
      {
        id: "term",
        accessorFn: (row: InstallmentPaymentDto) => {
          return <Typography>{row.installmentTermNumber}</Typography>;
        }, //simple recommended way to define a column
        header: "งวด",
        enableHiding: false,
        enablePinning: false,
        enableSorting: false,
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <Box width={100}>
            <Typography noWrap>{renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        id: "paidDueAmount",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Typography>
            {currencyFormat.format(row.paidDueAmount || 0)}
          </Typography>
        ),
        header: "ยอดจ่าย",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "paidFineAmount",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Typography>
            {currencyFormat.format(row.paidFineAmount || 0)}
          </Typography>
        ),
        header: "ยอดค่าปรับ",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "totalAmount",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Typography>
            {row.totalAmount ? currencyFormat.format(row.totalAmount) : "-"}
          </Typography>
        ),
        header: "ยอดรวม",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "note",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Typography>{row.note}</Typography>
        ),
        header: "บันทึก",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "createdByUserId",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Typography>
            {formatOptionalFullName(row.createdByName, row.createdByLastname)}
          </Typography>
        ),
        header: "รับโดย",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        enableSorting: false,
      },
      {
        id: "doocuments",
        accessorFn: (row: InstallmentPaymentDto) => (
          <Stack direction={"row"} gap={1}>
            {row.documents?.map((doc) => (
              <IconButton
                onClick={() => {
                  window.open(doc.downloadUrl);
                }}
              >
                <DescriptionIcon />
              </IconButton>
            ))}
          </Stack>
        ),
        header: "ไฟล์",
        enableHiding: false,
        enablePinning: false,
        muiTableBodyCellProps: {
          align: "right",
        },
        grow: false,
        size: 50,
        enableSorting: false,
      },
    ],
    [currencyFormat]
  );

  const table = useMaterialReactTable({
    columns: columns,
    data: props.data || [],
    layoutMode: "semantic",
    enablePagination: false,
    enableColumnPinning: true,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableSorting: true,
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnVirtualization: false,
    enableColumnOrdering: false,
    enableBottomToolbar: false,
    positionGlobalFilter: "left",
    enableTopToolbar: false,
    manualSorting: true,
    paginationDisplayMode: "pages",
    onSortingChange: setSorting,
    localization: {
      rowsPerPage: "จำนวนต่อหน้า",
    },
    manualPagination: true,
    initialState: {
      showGlobalFilter: false,
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    state: {
      sorting,
      columnVisibility: { totalProfit: shopCtx.isOwner },
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) =>
      row.original.type !== InstallmentPaymentDto.type.DOWN_PAYMENT && (
        <>
          <IconButton
            onClick={() => {
              openDeleteDialog(row.original.id!);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <InstallmentPaymentDeleteDialog
            open={open[row.original.id!]}
            onClose={() => closeDeleteDialog(row.original.id!)}
            installmentPaymentDto={row.original!}
          />
        </>
      ),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 12, //make actions column wider
      },
    },
    muiTableHeadCellProps: {
      sx: {
        borderRight: "1px solid #e0e0e0", //add a border between columns
        backgroundColor: "#455a64",
        color: "white",
        fontWeight: "500",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "grey.100",
          },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "1px solid #eeeeee", //add a border between columns
      },
    },
  });
  return (
    <>
      {props.data && props.data.length > 0 ? (
        <MaterialReactTable table={table} />
      ) : (
        <Stack width="100%" my={4} justifyContent="center" alignItems="center">
          <Typography>ยังไม่มีประวัติ</Typography>
        </Stack>
      )}
    </>
  );
};
