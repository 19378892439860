/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierPaymentChannelDto } from './FinancierPaymentChannelDto';
import type { PhoneNumber } from './PhoneNumber';
export type FinancierDto = {
    id?: string;
    name: string;
    address: string;
    lineId?: string;
    facebookUrl: string;
    phoneNumbers: Array<PhoneNumber>;
    status?: FinancierDto.status;
    expireAt?: string;
    avatarUrl?: string;
    financierUserRole?: FinancierDto.financierUserRole;
    paymentChannels?: Array<FinancierPaymentChannelDto>;
};
export namespace FinancierDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
    export enum financierUserRole {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    }
}

