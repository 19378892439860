import React, { Suspense } from "react";
import "./App.css";

import { ClerkProvider } from "@clerk/clerk-react";
import { thTH } from "@clerk/localizations";
import { createTheme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { DialogProvider } from "./context/DialogContext";
import { RouterDefinition } from "./route/RouterDefinition";

const theme = createTheme({
  palette: {
    primary: {
      main: "#263238",
    },
  },
  typography: {
    fontFamily: "Kanit",
  },
});

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <ThemeProvider theme={theme}>
            <ClerkProvider
              localization={thTH}
              publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY!}
            >
              <DialogProvider>
                <Toaster />

                <NuqsAdapter>
                  <RouterProvider router={RouterDefinition} />
                </NuqsAdapter>
              </DialogProvider>
            </ClerkProvider>
          </ThemeProvider>
        </Suspense>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
