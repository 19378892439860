import { useMutation } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";

export const useInstallmentPlanAggregationCalculateMutation = (
    shopId: string
) => {
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [shopId],
        mutationFn: () =>
            backendApiClient.report.calculateInstallmentPlanMonthlyAggregation({ shopId: shopId }),
        retry: 0,
    });
};