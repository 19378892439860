import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { InstallmentPaymentSumReport } from "../../../../client/motalvip-apis/generated";
import { paymentChannelConfig } from "../../../../config/PaymentChannelAssetConfig";

type InstallmentPaymentSumReportCardProps = {
  report: InstallmentPaymentSumReport;
};

const InstallmentPaymentSumReportCard: React.FC<
  InstallmentPaymentSumReportCardProps
> = ({ report }) => {
  const config = paymentChannelConfig.find(
    (config) =>
      // @ts-ignore
      config.type === report.paymentChannelType &&
      (config.bankCode === report.bankCode || config.bankCode === undefined)
  );

  return (
    <Card
      sx={{
        display: "flex",
        padding: 0.5,
      }}
    >
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography variant="h6" component="div">
          {!config ? "ไม่ได้กำหนด" : config!.name}
        </Typography>
        {report.paymentChannelType ===
          InstallmentPaymentSumReport.paymentChannelType.BANK_ACCOUNT && (
          <>
            <Typography variant="subtitle1" color="text.secondary">
              {report.accountName}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {report.accountId}
            </Typography>
          </>
        )}
        <Box sx={{ marginTop: 0 }}>
          <Typography variant="body1">
            <span
              style={{
                opacity: 0.6,
              }}
            >
              ยอดรวม
            </span>{" "}
            {Number(report.sumTotal?.toFixed(2))?.toLocaleString() || 0} บาท
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <span
              style={{
                opacity: 0.9,
              }}
            >
              จำนวน
            </span>{" "}
            {Number(report.rowCount?.toFixed(2))?.toLocaleString() || 0}
          </Typography>
        </Box>
      </CardContent>
      {config && (
        <img
          src={config.logo}
          alt={report.paymentChannelType}
          style={{
            padding: 10,
            borderRadius: 5,
            width: 56,
            height: 56,
            backgroundColor: config.color,
          }}
        />
      )}
    </Card>
  );
};

export default InstallmentPaymentSumReportCard;
