import dayjs from "dayjs";

export function dayDiff(
  dateStart: Date,
  dateEnd: Date | string = new Date()
): number {
  if (typeof dateEnd === "string") dateEnd = new Date();

  let delta: number = Math.abs(dateStart.getTime() - dateEnd.getTime());
  return Math.ceil(delta / (1000 * 3600 * 24));
}

export function formatLongReadableThaiDate(date: Date) {
  const formatted = date.toLocaleDateString("th-TH", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return formatted;
}

export function formatShortReadableThaiDate(date: Date, withTime = false) {
  let dateString = "DD MMM YYYY";
  if (withTime) dateString += " HH:mm";
  const formatted = dayjs(date).locale("th").format(dateString);
  return formatted;
}
