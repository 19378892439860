import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ShopIcon from '@mui/icons-material/Shop'
import { Avatar, Box, Button, Card, CardContent, FormControl, IconButton, Stack, styled, TextField, Typography, useTheme } from "@mui/material"
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import toast from 'react-hot-toast'
import { useParams } from "react-router-dom"
import { useBackendApiClient } from '../../../client/motalvip-apis'
import { ShopDto } from '../../../client/motalvip-apis/generated'
import { useShopUpdateMutation } from '../../../client/query-client'
import CustomInputLabel from "../../../component/CustomInputLabel"
import { grey } from '@mui/material/colors'

// import UploadZone from "../component/UploadZone"
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export const ShopDetailScreen = () => {
    const theme = useTheme()
    const { shopId } = useParams()
    const apiClient = useBackendApiClient()
    const shopQuery = useQuery({
        enabled: !!shopId,
        queryKey: ["shop", shopId],
        queryFn: () => apiClient.shop.getShopById({ shopId: shopId || '' }),
        refetchOnWindowFocus: true
    })
    const shopUpdateMutation = useShopUpdateMutation(shopId || '')
    // const phoneNumbersQuery = usePhoneNumberQuery(shopId || '')
    const { control, handleSubmit, reset, formState: { errors, isDirty } } = useForm<ShopDto>({
        defaultValues: {
            address: '',
            name: '',
            phoneNumbers: [{ phoneNumber: '' }],
            // phoneNumbers: '',
            facebookUrl: '',
            lineId: ''
        },
        mode: 'all'
    });

    const [tempProfileImage, setTempProfileImage] = useState<File | null>(null)

    const uploadFile = async ({ file }: { file: File }) => {
        try {
            const uploadUrlResponse = await apiClient.shop.getShopAvatarUploadUrl({ requestBody: { shopId: shopId!, fileName: "profile_" + shopId! } });
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', uploadUrlResponse.uploadUrl!, true);
            xhr.setRequestHeader('Content-Type', file.type);
            xhr.onload = () => {
                if (xhr.status === 200) {
                    apiClient.shop.confirmShopAvatarUpload({ requestBody: { shopId: shopId!, objectKey: uploadUrlResponse.objectKey || '' } })
                    setTempProfileImage(null);
                    shopQuery.refetch()
                } else {
                    console.error('Upload failed', xhr.responseText);
                }
            };
            xhr.onerror = () => {
                console.error('Upload error', xhr.responseText);
            };

            xhr.send(file);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };

    useEffect(() => {
        if (!shopQuery.isLoading && shopQuery.data) {
            reset({
                address: shopQuery.data.address,
                name: shopQuery.data.name, facebookUrl:
                    shopQuery.data.facebookUrl, lineId:
                    shopQuery.data.lineId, phoneNumbers:
                    [{ phoneNumber: shopQuery.data.phoneNumbers.at(0)?.phoneNumber || '' }]
            });
        }
    }, [shopQuery.isLoading, shopQuery.data, reset]);

    const onSubmit = () => {
        handleSubmit((data) => {

            if (tempProfileImage) {
                uploadFile({ file: tempProfileImage });
            }
            // const dirtyFieldsData = Object.keys(dirtyFields).reduce((acc, key) => {
            //     acc[key as keyof ShopDto] = data[key as keyof ShopDto];
            //     return acc;
            // }, {} as Partial<ShopDto>);

            shopUpdateMutation.mutate({ ...data, id: shopId }, {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ');
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
                    console.error('Error updating shop:', error);
                }
            });
        })()
    }

    const onCancel = () => {
        setTempProfileImage(null)
        shopQuery.refetch()
        reset({
            address: shopQuery.data?.address,
            name: shopQuery.data?.name,
            facebookUrl: shopQuery.data?.facebookUrl,
            lineId: shopQuery.data?.lineId,
            phoneNumbers: [{ phoneNumber: shopQuery.data?.phoneNumbers.at(0)?.phoneNumber || '' }]
        });
    }

    return (
        <>
            <Box sx={{ p: 2, mb: 8 }}>
                <Stack direction='row' alignItems='center'>
                    <Typography variant="h5" pb={2}>ข้อมูลร้านค้า</Typography>
                </Stack>
                <Card sx={{ width: { md: '100%', lg: '60%' } }}>
                    <CardContent sx={{ p: 2 }}>
                        <Stack gap={4}>
                            <Stack
                                direction={'row'}
                                gap={2}
                                justifyContent='center'
                                alignItems='center'
                                sx={{ p: 2, border: `2px solid ${theme.palette.grey[300]}`, borderRadius: 2 }}>
                                <Avatar
                                    src={
                                        tempProfileImage ? URL.createObjectURL(tempProfileImage) : shopQuery.data?.avatarUrl
                                    }
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        border: `4px solid ${theme.palette.grey[300]}`
                                    }}
                                    variant="circular">
                                    <ShopIcon sx={{ fontSize: '80px' }} />
                                </Avatar>
                                <Stack gap={1}>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="outlined"
                                        tabIndex={-1}
                                        color='primary'
                                        sx={{ width: 150, borderRadius: 100 }}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        อัพโหลด
                                        <VisuallyHiddenInput type="file" onChange={(event) => {
                                            const file = event.target.files?.[0];
                                            if (file) {
                                                setTempProfileImage(file);
                                            }
                                        }} />
                                    </Button>
                                    <Typography variant='body2' color={grey[600]}>โลโก้ร้านค้า ขนาด 150x150 px</Typography>
                                </Stack>
                            </Stack>
                            <Stack gap={2}>
                                <FormControl fullWidth error={!!errors.name}>
                                    <CustomInputLabel>ชื่อร้านค้า</CustomInputLabel>
                                    <Controller
                                        name="name"
                                        control={control}
                                        rules={{ required: "ข้อมูลไม่ครบ" }}
                                        render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                    />
                                    {errors.name && <Typography color="error">{errors.name.message}</Typography>}
                                </FormControl>
                                <FormControl fullWidth error={!!errors.address}>
                                    <CustomInputLabel>ที่อยู่ *</CustomInputLabel>
                                    <Controller
                                        name="address"
                                        control={control}
                                        rules={{ required: "ข้อมูลไม่ครบ" }}
                                        render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                    />
                                    {errors.address && <Typography color="error">{errors.address.message}</Typography>}
                                </FormControl>
                                <FormControl fullWidth error={!!errors?.phoneNumbers?.[0]?.phoneNumber?.message}>
                                    <CustomInputLabel>หมายเลขโทรศัพท์ *</CustomInputLabel>
                                    <Controller
                                        name="phoneNumbers.0.phoneNumber"
                                        control={control}
                                        rules={{ required: "ข้อมูลไม่ครบ" }}
                                        render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                    />
                                    {errors.phoneNumbers && <Typography color="error">{errors?.phoneNumbers[0]?.phoneNumber?.message}</Typography>}
                                </FormControl>
                                <Stack direction={{ lg: 'row' }} gap={2}>
                                    <FormControl fullWidth error={!!errors.facebookUrl}>
                                        <CustomInputLabel>Facebook ร้าน *</CustomInputLabel>
                                        <Controller
                                            name="facebookUrl"
                                            control={control}
                                            rules={{ required: "ข้อมูลไม่ครบ", pattern: { value: /^(ftp|http|https):\/\/[^ "]+$/, message: 'กรุณากรอก URL ของ Facebook ให้ถูกต้อง' } }}
                                            render={({ field }) => <TextField {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.facebookUrl && <Typography color="error">{errors.facebookUrl.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <CustomInputLabel>LINE ID ร้าน</CustomInputLabel>
                                        <Controller
                                            name="lineId"
                                            control={control}
                                            render={({ field }) => <TextField  {...field} size='small' variant="outlined" />}
                                        />
                                    </FormControl>
                                </Stack>
                                <Stack>
                                    <CustomInputLabel>ลิ้งโปรไฟล์ร้าน</CustomInputLabel>
                                    <Stack direction='row' gap={1} alignItems='flex-end' justifyContent='flex-start'>
                                        <Typography sx={{ lineHeight: '32px' }}>{shopId ? `${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?shopId=${shopId}` : '-'}</Typography>
                                        <IconButton size="small" onClick={() => {
                                            navigator.clipboard.writeText(`${process.env.REACT_APP_MOTAL_CUSTOMER_WEB_URL}?shopId=${shopId}`)
                                                .then(() => {
                                                    toast.success('คัดลอกสำเร็จ');
                                                })
                                        }}>
                                            <ContentCopyIcon fontSize='inherit' />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </CardContent>
                </Card>
            </Box >
            <Box>
            </Box >
            <Box sx={{ visibility: (isDirty || tempProfileImage) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={onCancel}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>
                        ยกเลิก
                    </Button>
                    <Button variant='contained' size='large'
                        onClick={onSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>
                        บันทึก
                    </Button>
                </Stack>
            </Box >

        </>
    )
}