export const uploadFile = async (
  file: File,
  uploadUrl: string,
  onUploaded: (file: File) => void
) => {
  try {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", uploadUrl, true);
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.onload = () => {
      if (xhr.status === 200) {
        onUploaded(file);
      } else {
        console.error("Upload failed", xhr.responseText);
      }
    };
    xhr.send(file);
  } catch (error) {
    console.error("Error uploading file", error);
  }
};

export const downloadFile = async (url: string, filename: string) => {
  let link = document.createElement("a");
  link.download = filename;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
