import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, Button, FormControl, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { MaterialReactTable, MRT_SortingState, useMaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { InstallmentPlanDto } from '../../../client/motalvip-apis/generated';
import { loanRegistrationStatusOptions } from '../../../component/StatusChip/LoanRegistrationStatusChip';
import { useShopContext } from '../../../context/ShopContextProvider';
import { LoanRegistrationColumnDef } from '../../../util/LoanColumnDef';
import { useLoansQuery } from '../../../client/query-client/useLoansQuery';

export const LoanCreateListScreen = () => {
    const nav = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams()
    const search = searchParams.get('search') || '';
    const transactionDateFrom = searchParams.get('transactionDateFrom') || undefined;
    const transactionDateTo = searchParams.get('transactionDateTo') || undefined;
    const filterStatusIndex = searchParams.getAll('filterStatusIndex');
    const pageIndex = parseInt(searchParams.get('pageIndex') || '0');
    const pageSize = parseInt(searchParams.get('pageSize') || '10');

    const [pagination, setPagination] = useState({
        pageIndex: pageIndex, //initial page index
        pageSize: pageSize, //default page size
    });
    useEffect(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('pageIndex', pagination.pageIndex.toString());
        newSearchParams.set('pageSize', pagination.pageSize.toString());
        setSearchParams(newSearchParams)
    }, [pagination, searchParams, setSearchParams])
    // const [transactionDateFrom, setTransactionDateFrom] = useState<string | undefined>(undefined);
    // const [transactionDateTo, setTransactionDateTo] = useState<string | undefined>(undefined);
    // const [filterStatus, setFilterStatus] = useState<{ value: Loan.registrationStatus, display: string }[]>([]);
    const { shopId } = useParams();
    // const [search, setSearch] = useState<string>('');
    // const [pagination, setPagination] = useState({
    //     pageIndex: 0,
    //     pageSize: 10, //customize the default page size
    // });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: searchParams.get('sorting') || 'sequenceNumber', desc: searchParams.get('sort') === 'desc' }]);
    useEffect(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (sorting.length > 0) {
            newSearchParams.set('sorting', sorting[0].id);
            newSearchParams.set('sort', sorting[0].desc ? 'desc' : 'asc');
            setSearchParams(newSearchParams)
        }
    }, [sorting, searchParams, setSearchParams])
    // const sorting = searchParams.get('sorting') || undefined;

    const shopCtx = useShopContext();
    const loansQuery = useLoansQuery({
        shopId: shopId || '',
        search: search.length >= 3 ? search : '',
        status: [InstallmentPlanDto.status.DRAFT, InstallmentPlanDto.status.PENDING_CONTRACT_DOCUMENTS, InstallmentPlanDto.status.PENDING_CONFIRM_DOWN_PAYMENT],
        registrationStatus: filterStatusIndex.length > 0 ? filterStatusIndex.map((i) => loanRegistrationStatusOptions[parseInt(i)].value) : undefined,
        page: pageIndex + 1,
        registrationDateFrom: transactionDateFrom,
        registrationDateTo: transactionDateTo,
        limit: pageSize,
        sortBy: sorting.length > 0 ? sorting[0].id : undefined,
        sort: sorting.length > 0 ? sorting[0].desc ? 'desc' : 'asc' : undefined,
    });


    // useEffect(() => {
    //     loansQuery.refetch()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [pageIndex])

    const table = useMaterialReactTable({
        columns: LoanRegistrationColumnDef,
        data: loansQuery.data?.content || [],
        layoutMode: 'semantic',
        enableColumnPinning: true,
        enableGlobalFilter: true,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        onSortingChange: setSorting,
        muiPaginationProps: {
            shape: 'rounded',
            page: pageIndex + 1,
            rowsPerPageOptions: [10, 25, 50, 100],
            defaultPage: 0,
            showRowsPerPage: true,
            showFirstButton: false,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        rowCount: loansQuery.data?.pagination?.totalElements || 1,
        onPaginationChange: setPagination,
        // onPaginationChange: (p) => {
        //     setSearchParams({ pageIndex: p.pageIndex.toString(), pageSize: p.pageSize.toString() })
        // },
        initialState: {
            showGlobalFilter: true,
            pagination,
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        state: {
            isLoading: loansQuery.isFetching,
            pagination: {
                pageIndex: pageIndex,
                pageSize: pageSize
            },
            sorting,
            columnVisibility: { 'totalProfit': shopCtx.isOwner },
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row }) => (
            <IconButton onClick={() => nav(`/${shopId}/loan/create?id=${row.original.id}`)}>
                <ArrowForwardIosIcon />
            </IconButton>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500',
                padding: 1
            },
        },
        muiTableBodyProps: {
            sx: {
                //stripe the rows, make odd rows a darker color
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                borderRight: '1px solid #eeeeee', //add a border between columns
                padding: 1
            },
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
            <Box p={2}>
                <Typography variant="h5" pb={2}>{'รายการสมัครสินเชื่อ'}</Typography>
                <Stack gap={2} sx={{ maxWidth: '90vw' }}>
                    <Stack direction={{ lg: 'row' }} gap={2} justifyContent='space-between' alignItems='center'>
                        <TextField
                            fullWidth
                            sx={{ backgroundColor: 'white', flex: 2 }}
                            value={search}
                            onChange={(e) => {
                                const newSearchParams = new URLSearchParams(searchParams);
                                newSearchParams.set('search', e.target.value);
                                setSearchParams(newSearchParams)
                            }
                            }
                            variant="outlined"
                            placeholder="ค้นหา หมายเลขเคส, ชื่อ-นามสกุล, เลขบัตร, พาสปอร์ต, เบอร์โทร, IMEI, Serial Number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            fullWidth
                            variant='contained'
                            size='medium'
                            onClick={() =>
                                nav(`/${shopId}/loan/create`)
                                // createLoanDraftMutation.mutate()
                            }
                            sx={{ height: '56px', width: { xs: '100%', lg: '40%' } }}
                        >
                            เพิ่มรายการสมัครสินเชื่อ
                        </Button>
                    </Stack>
                    <Stack direction={{ lg: 'row' }} gap={2}>
                        <Autocomplete
                            fullWidth
                            multiple
                            id='selected-registration-status'
                            value={loanRegistrationStatusOptions.filter((o, i) => filterStatusIndex.includes(i.toString()))}
                            onChange={(event, newValue) => {
                                const newSearchParams = new URLSearchParams(searchParams);
                                newSearchParams.delete('filterStatusIndex');
                                newValue.forEach((o) => {
                                    newSearchParams.append('filterStatusIndex', loanRegistrationStatusOptions.indexOf(o).toString());
                                });
                                setSearchParams(newSearchParams);
                            }}
                            options={loanRegistrationStatusOptions}
                            getOptionLabel={(o) => o.display}
                            renderInput={(params) => (
                                <TextField {...params} label='สถานะการสมัคร' placeholder='' />
                            )}
                        />
                        <FormControl fullWidth>
                            <DatePicker
                                format="DD MMM YYYY"
                                label="วันที่สมัคร (เริ่มต้น)"
                                value={transactionDateFrom ? dayjs(transactionDateFrom).locale("th") : null}  // Convert to Dayjs
                                onChange={(date) => {
                                    const newSearchParams = new URLSearchParams(searchParams);
                                    if (date) {
                                        newSearchParams.set('transactionDateFrom', date.format('YYYY-MM-DD'));
                                    } else {
                                        newSearchParams.delete('transactionDateFrom');
                                    }
                                    setSearchParams(newSearchParams);
                                }}
                                slotProps={{ textField: { variant: 'outlined' } }} />
                        </FormControl>
                        <FormControl fullWidth>
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="วันที่สมัคร (สิ้นสุด)"
                                value={transactionDateTo ? dayjs(transactionDateTo) : null}  // Convert to Dayjs
                                onChange={(date) => {
                                    const newSearchParams = new URLSearchParams(searchParams);
                                    if (date) {
                                        newSearchParams.set('transactionDateTo', date.format('YYYY-MM-DD'));
                                    } else {
                                        newSearchParams.delete('transactionDateTo');
                                    }
                                    setSearchParams(newSearchParams);
                                }}
                                slotProps={{ textField: { variant: 'outlined' } }} />
                        </FormControl>

                    </Stack>
                    <Box sx={{ maxWidth: '100%' }}>
                        <MaterialReactTable table={table} />
                    </Box>
                </Stack>
            </Box>
        </LocalizationProvider>
    );
}
