import { useQuery } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { InstallmentPlanDto } from "../motalvip-apis/generated";
import { QueryKey } from ".";

export const useLoansQuery = (req: {
    shopId?: string;
    search?: string;
    scheduledDaysFrom?: number;
    scheduledDaysTo?: number;
    appointmentDaysFrom?: number;
    appointmentDaysTo?: number;
    transactionDateFrom?: string;
    transactionDateTo?: string;
    registrationDateFrom?: string;
    registrationDateTo?: string;
    customerIdentifier?: string;
    page?: number;
    limit?: number;
    status?: InstallmentPlanDto.status[];
    isAppointment?: boolean;
    needCost?: boolean;
    registrationStatus?: InstallmentPlanDto.registrationStatus[];
    sortBy?: string;
    sort?: string;
}) => {
    const apiClient = useBackendApiClient();
    return useQuery({
        queryKey: [
            QueryKey.LOANS,
            req.shopId,
            req.search,
            req.page,
            req.scheduledDaysFrom,
            req.scheduledDaysTo,
            req.status,
            req.isAppointment,
            req.sortBy,
            req.sort,
            req.transactionDateFrom,
            req.transactionDateTo,
            req.registrationStatus,
            req.registrationDateFrom,
            req.registrationDateTo,
            req.customerIdentifier,
            req.needCost,
        ],
        queryFn: () =>
            apiClient.installmentPlan.searchLoans({
                requestBody: {
                    shopId: req.shopId,
                    downPaymentDateFrom: req.transactionDateFrom,
                    downPaymentDateTo: req.transactionDateTo,
                    limit: req.limit,
                    page: req.page,
                    status: req.status,
                    registrationStatus: req.registrationStatus,
                    registrationDateFrom: req.registrationDateFrom,
                    registrationDateTo: req.registrationDateTo,
                    scheduledDaysFrom: req.scheduledDaysFrom,
                    scheduledDaysTo: req.scheduledDaysTo,
                    appointmentDaysFrom: req.appointmentDaysFrom,
                    appointmentDaysTo: req.appointmentDaysTo,
                    searchText: req.search,
                    isAppointment: req.isAppointment,
                    needCost: req.needCost,
                    sort: req.sort,
                    sortBy: req.sortBy,
                    customerIdentifier: req.customerIdentifier,
                },
            }),
    });
};