/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstallmentPaymentSumReport = {
    shopPaymentChannelId?: string;
    accountName?: string;
    accountId?: string;
    paymentChannelType?: InstallmentPaymentSumReport.paymentChannelType;
    bankCode?: InstallmentPaymentSumReport.bankCode;
    sumTotal?: number;
    rowCount?: number;
};
export namespace InstallmentPaymentSumReport {
    export enum paymentChannelType {
        CASH = 'CASH',
        PROMPT_PAY = 'PROMPT_PAY',
        PROMPT_PAY_QR = 'PROMPT_PAY_QR',
        BANK_ACCOUNT = 'BANK_ACCOUNT',
        AUTOMATIC_QR = 'AUTOMATIC_QR',
    }
    export enum bankCode {
        BBL = 'BBL',
        BAY = 'BAY',
        SCB = 'SCB',
        KBANK = 'KBANK',
        KTB = 'KTB',
        TTB = 'TTB',
    }
}

