import { useAuth } from "@clerk/clerk-react";
import { useMemo } from "react";
import { BackendApiClient } from "./generated";

export const useBackendApiClient = () => {
  const auth = useAuth();
  const client = useMemo(() => {
    return new BackendApiClient({
      BASE: process.env.REACT_APP_MOTAL_API_URL,
      TOKEN: async () => {
        const token = await auth.getToken({ template: "default" });
        return token || ""; // Return an empty string if the token is null
      },
    });
  }, [auth]);
  return client;
};
