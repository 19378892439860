/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FinancierUserInvitationRequest = {
    financierId?: string;
    email?: string;
    role?: FinancierUserInvitationRequest.role;
};
export namespace FinancierUserInvitationRequest {
    export enum role {
        OWNER = 'OWNER',
        ADMIN = 'ADMIN',
        EMPLOYEE = 'EMPLOYEE',
        SHOP_AGENT = 'SHOP_AGENT',
    }
}

