import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBackendApiClient } from "../motalvip-apis";
import { InstallmentPlanDocumentDto } from "../motalvip-apis/generated";

export const useCreateInstallmentPlanDocumentMutation = (
    installmentPlanId: string
) => {
    const queryClient = useQueryClient();
    const backendApiClient = useBackendApiClient();
    return useMutation({
        mutationKey: [installmentPlanId],
        mutationFn: (req: InstallmentPlanDocumentDto) =>
            backendApiClient.installmentPlan.createInstallmentPlanDocument({
                requestBody: req,
            }),
        onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: [installmentPlanId] });
        },
        retry: 0,
    });
};