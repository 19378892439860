import { Box, Button, Card, CardContent, Dialog, FormControl, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { InstallmentPlanDto } from "../../../client/motalvip-apis/generated";
import { useInstallmentPlanCostUpdateMutation } from "../../../client/query-client/useInstallmentPlanCostUpdateMutation";
import CustomInputLabel from "../../CustomInputLabel";


interface InstallmentPlanCostDialogProps {
    installmentPlanDto?: InstallmentPlanDto;
    open: boolean;
    onClose: () => void;
}

export const InstallmentPlanCostDialog = (props: InstallmentPlanCostDialogProps) => {

    const { installmentPlanId } = useParams()
    const installmentPlanCostUpdateMutation = useInstallmentPlanCostUpdateMutation(installmentPlanId || '')

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { errors }
    } = useForm<InstallmentPlanDto>({
        defaultValues: {
            id: installmentPlanId,
            commissionAmount: props.installmentPlanDto?.commissionAmount || 0,
            otherCostAmount: props.installmentPlanDto?.otherCostAmount || 0,
            product: {
                purchaseCost: props.installmentPlanDto?.product?.purchaseCost || 0
            }
        },
        mode: 'all'
    });

    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const onSubmit = () => {
        handleSubmit((data) => {
            installmentPlanCostUpdateMutation.mutate(getValues(), {
                onSuccess: () => {
                    toast.success('บันทึกข้อมูลสำเร็จ')
                    props.onClose()
                },
                onError: (error) => {
                    toast.error('เกิดข้อผิดพลาด')
                    props.onClose()
                }
            });
        })()
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onCloseDialog = () => {
        reset();
        props.onClose();
    }

    return (

        <Dialog
            fullWidth={isMobile}
            maxWidth={isMobile ? false : 'md'}
            open={props.open}
            onClose={onCloseDialog}
            PaperProps={{ sx: { borderRadius: 2 } }}
        >
            <Box sx={{ bgcolor: 'white', width: isMobile ? '100%' : theme.breakpoints.values.md }} >
                <Box sx={{ p: 2, justifyContent: 'space-between', bgcolor: '#263238' }}>
                    <Stack direction='column' alignItems='center' sx={{ width: '100%' }}>
                        <Typography variant="h5" color='white'>{`บันทึกค่าใช้จ่าย`}</Typography>
                    </Stack>
                </Box>
                <Box sx={{ p: 2, width: '100%', bgcolor: 'white' }}>
                    <Card sx={{ my: 1, width: { md: '100%' } }}>
                        <CardContent sx={{ padding: 2 }}>
                            <Stack direction={'column'} gap={2}>
                                <Typography variant="h6">ข้อมูลค่าใช้จ่าย</Typography>
                                <Stack direction={{ sm: 'column' }} gap={2}>
                                    <FormControl fullWidth error={errors.product?.purchaseCost?.message ? true : false}>
                                        <CustomInputLabel>ราคาเครื่อง</CustomInputLabel>
                                        <Controller
                                            name="product.purchaseCost"
                                            control={control}
                                            rules={{
                                                min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                max: { value: 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(999999999)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.product?.purchaseCost && <Typography color="error">{errors.product.purchaseCost.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={errors.commissionAmount?.message ? true : false}>
                                        <CustomInputLabel>ค่าคอมมิชชั่น</CustomInputLabel>
                                        <Controller
                                            name="commissionAmount"
                                            control={control}
                                            rules={{
                                                min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                max: { value: 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(999999999)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.commissionAmount && <Typography color="error">{errors.commissionAmount.message}</Typography>}
                                    </FormControl>
                                    <FormControl fullWidth error={errors.otherCostAmount?.message ? true : false}>
                                        <CustomInputLabel>ค่าใช้จ่ายอื่นๆ</CustomInputLabel>
                                        <Controller
                                            name="otherCostAmount"
                                            control={control}
                                            rules={{
                                                min: { value: 0, message: 'เป็นตัวเลขบวกเท่านั้น' },
                                                max: { value: 999999999, message: `ยอดเงินต้องไม่เกิน ${currencyFormat.format(999999999)}` }
                                            }}
                                            render={({ field }) => <TextField type="number" {...field} size='small' variant="outlined" />}
                                        />
                                        {errors.otherCostAmount && <Typography color="error">{errors.otherCostAmount.message}</Typography>}
                                    </FormControl>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>

                </Box>
                <Box sx={{ p: 2, pt: 0, bgcolor: 'white', width: '100%' }} >
                    <Stack direction='row' gap={1} justifyContent='center'>
                        <Button variant='outlined' size='large'
                            onClick={onCloseDialog}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>ยกเลิก</Button>
                        <Button variant='contained' size='large'
                            onClick={onSubmit}
                            sx={{
                                width: {
                                    xs: '100%', sm: '100%', md: 200
                                },
                            }}>บันทึก</Button>
                    </Stack>
                </Box>
            </Box>
        </Dialog>
    );
};