import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { InstallmentPlanDto } from "../../client/motalvip-apis/generated";
import {
  useSearchMasterDeviceBrands,
  useSearchMasterDeviceModels,
} from "../../client/query-client";
import { useDebouncedValue } from "../../util/useDebouncedValue";
import CustomInputLabel from "../CustomInputLabel";

const DEFAULT_LIMIT = 300;
const DEBOUNCE_DELAY = 250;

const TogglableLabel = ({
  toggleFormModeText,
  onModeChanged,
  label,
}: {
  toggleFormModeText: string;
  onModeChanged: () => void;
  label: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 1,
      }}
    >
      <CustomInputLabel>{label}</CustomInputLabel>
      <Button variant="contained" onClick={onModeChanged}>
        {toggleFormModeText}
      </Button>
    </Box>
  );
};

const SearchableBrandField = ({
  onSelected,
  label,
}: {
  onSelected?: (device: string) => void;
  label: string;
}) => {
  const { data } = useSearchMasterDeviceBrands({
    limit: DEFAULT_LIMIT,
    brand: "",
    page: 0,
  });

  const mapped = useMemo(() => {
    const mapped =
      data?.data.map((d) => {
        return d.displayName;
      }) ?? [];
    // This is to get rid of duplicate in case there is a bug in the backend
    // that return duplicate data.
    const set = new Set(mapped);
    const arr = Array.from(set);
    const upperCaseFirstLetter = (str: string) =>
      str.charAt(0).toUpperCase() + str.slice(1);
    const upped = arr.map(upperCaseFirstLetter);
    return upped;
  }, [data?.data]);

  const onComboListSelected = (value?: string | null) => {
    if (!value) return;
    if (!data?.data) return;

    onSelected?.(value);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        options={mapped}
        sx={{ maxWidth: 300, flex: 1 }}
        onChange={(_e, v) => {
          onComboListSelected(v);
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
};

const SearchableModelField = ({
  onSelected,
  label,
  brand,
  disabled,
}: {
  onSelected?: (device: string) => void;
  label: string;
  brand: string;
  disabled: boolean;
}) => {
  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebouncedValue(inputValue, DEBOUNCE_DELAY);
  const { data } = useSearchMasterDeviceModels({
    limit: DEFAULT_LIMIT,
    model: debouncedSearch,
    brand,
    page: 0,
  });
  const mapped = data?.data.map((d) => d.displayName) ?? [];

  const onComboListSelected = (value?: string | null) => {
    if (!value) return;
    if (!data?.data) return;

    onSelected?.(value);
  };

  return (
    <Autocomplete
      disablePortal
      options={mapped}
      sx={{ maxWidth: 300, flex: 1 }}
      filterOptions={(options) => {
        return options;
      }}
      onChange={(_e, v) => {
        onComboListSelected(v);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      disabled={disabled}
    />
  );
};

export const InstallmentMasterDeviceModelField = ({
  form,
}: {
  form: UseFormReturn<InstallmentPlanDto, any, undefined>;
}) => {
  const [mode, setMode] = useState<"combobox" | "freetext">("combobox");

  const onModeChanged = () => {
    setMode((prev) => (prev === "combobox" ? "freetext" : "combobox"));
    form.setValue("product.brand", undefined);
    form.setValue("product.model", undefined);
    form.setValue("product.color", undefined);
  };

  const toggleFormModeText =
    mode === "combobox" ? "รุ่นอื่นๆโปรดระบุ" : "เลือกจากรายการ";

  return mode === "combobox" ? (
    <>
      <FormControl required>
        <TogglableLabel
          toggleFormModeText={toggleFormModeText}
          onModeChanged={onModeChanged}
          label="แบรนด์และรุ่น"
        />

        <div
          style={{
            display: "flex",
            gap: "16px",
          }}
        >
          <SearchableBrandField
            label="แบรนด์"
            onSelected={(brand) => {
              form.setValue("product.brand", brand);
            }}
          />
          <SearchableModelField
            label="รุ่น"
            onSelected={(model) => {
              form.setValue("product.model", model);
            }}
            disabled={!form.watch("product.brand")}
            brand={form.watch("product.brand")!}
          />
        </div>
      </FormControl>
      <FormControl></FormControl>
    </>
  ) : (
    <>
      <FormControl fullWidth>
        <TogglableLabel
          toggleFormModeText={toggleFormModeText}
          onModeChanged={onModeChanged}
          label="แบรนด์"
        />
        <TextField
          {...form.register("product.brand")}
          required
          size="small"
          variant="outlined"
        />
      </FormControl>
      <Stack direction="row" gap={2}>
        <FormControl fullWidth>
          <CustomInputLabel>รุ่น</CustomInputLabel>
          <TextField
            required
            {...form.register("product.model")}
            size="small"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth>
          <CustomInputLabel>สี</CustomInputLabel>
          <TextField
            {...form.register("product.color")}
            size="small"
            variant="outlined"
          />
        </FormControl>
      </Stack>
    </>
  );
};
