import { Delete } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useBackendApiClient } from "../../../client/motalvip-apis";
import {
  ShopPaymentChannelCreateRequest,
  ShopPaymentChannelDto,
} from "../../../client/motalvip-apis/generated";
import CustomInputLabel from "../../../component/CustomInputLabel";
import PaymentChannelRecord from "../../../component/PaymentChannelRecord";
import { Spin } from "../../../component/common/Spin";
import { generateRandomString } from "../../../util/random-string";
import { usePaymentChannelQuery } from "../../../client/query-client/usePaymentChannelQuery";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ShopPaymentChannelScreen = () => {
  const { shopId } = useParams();
  const [newChannel, setNewChannel] = useState<ShopPaymentChannelCreateRequest>(
    {
      shopId: shopId || "",
      accountId: "",
      accountName: "",
    }
  );
  const [newChannelImage, setNewChannelImage] = useState<File | null>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setNewChannel({ ...newChannel, [name]: value });
  };
  const handleBangCodeChange = (e: any) => {
    setNewChannel({ ...newChannel, bankCode: e.target.value });
  };
  const client2 = useBackendApiClient();

  const paymentChannelsQuery = usePaymentChannelQuery(shopId || "");

  const createPaymentChannelMutation = useMutation({
    mutationFn: (req: ShopPaymentChannelCreateRequest) =>
      client2.shop.createShopPaymentChannel({ requestBody: req }),
    onSuccess: async (data, variables) => {
      if (variables.type !== ShopPaymentChannelDto.type.PROMPT_PAY_QR) {
        setNewChannel({
          shopId: shopId || "",
          accountId: "",
          accountName: "",
          bankCode: undefined,
          type: undefined,
        });
        paymentChannelsQuery.refetch();
        toast.success("บันทึกสำเร็จ");
      } else {
        const uploadUrl = await client2.shop.getShopQrCodeUploadUrl({
          requestBody: {
            shopId: shopId!,
            fileName: `qr_${generateRandomString(32)} `,
            paymentChannelId: data.shopPaymentChannelId || "",
          },
        });
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", uploadUrl.uploadUrl!, true);
        xhr.setRequestHeader("Content-Type", newChannelImage?.type || "");
        xhr.onload = async () => {
          if (xhr.status === 200) {
            await client2.shop.confirmShopQrCodeUpload({
              requestBody: {
                objectKey: uploadUrl.objectKey || "",
                paymentChannelId: data.shopPaymentChannelId || "",
              },
            });
            setNewChannel({
              shopId: shopId || "",
              accountId: "",
              accountName: "",
              bankCode: undefined,
              type: undefined,
            });
            setNewChannelImage(null);
            paymentChannelsQuery.refetch();
            toast.success("บันทึกสำเร็จ");

            // setTempProfileImage(null);
            // shopQuery.refetch()
          } else {
            console.error("Upload failed", xhr.responseText);
          }
        };
        xhr.onerror = () => {
          console.error("Upload error", xhr.responseText);
        };

        xhr.send(newChannelImage);
      }
    },
    onError: (error) => {
      debugger;
      console.error(error);
      toast.error("เกิดข้อผิดพลาด");
    },
  });
  const handleAddChannel = () => {
    const containsAllRequiredBankAccountFields =
      newChannel.type === ShopPaymentChannelDto.type.BANK_ACCOUNT &&
      newChannel.bankCode &&
      newChannel.accountId &&
      newChannel.accountName;

    const containsAllRequiredPromptPayFields =
      newChannel.type === ShopPaymentChannelDto.type.PROMPT_PAY &&
      newChannel.accountId &&
      newChannel.accountName;

    const containsAllRequiredPromptPayQrFields =
      newChannel.type === ShopPaymentChannelDto.type.PROMPT_PAY_QR &&
      newChannel.accountName &&
      newChannelImage;

    const containsAllRequiredAutomaticQrFields =
      newChannel.type === ShopPaymentChannelDto.type.AUTOMATIC_QR &&
      newChannel.accountName &&
      newChannel.integrationToken;

    const nonValid =
      !containsAllRequiredBankAccountFields &&
      !containsAllRequiredPromptPayFields &&
      !containsAllRequiredPromptPayQrFields &&
      !containsAllRequiredAutomaticQrFields;

    if (nonValid) {
      toast.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    createPaymentChannelMutation.mutate(newChannel);
  };

  return (
    <Spin spinning={paymentChannelsQuery.isLoading}>
      <Box sx={{ p: 2, mb: 8 }}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" pb={2}>
            ช่องทางจ่ายเงิน
          </Typography>
        </Stack>
        <Card sx={{ width: { md: "100%", lg: "60%" } }}>
          <CardContent sx={{ padding: 2 }}>
            <Stack gap={2}>
              <Stack gap={1} direction={"column"} sx={{ alignItems: "center" }}>
                {paymentChannelsQuery.data?.map((channel, index) => (
                  <Box sx={{ width: "100%" }}>
                    <PaymentChannelRecord paymentChannel={channel} />
                  </Box>
                ))}
              </Stack>

              <Divider />
              <Stack gap={2}>
                <Stack gap={2} direction={{ lg: "row" }}>
                  <FormControl fullWidth>
                    <CustomInputLabel id="type-label">ประเภท</CustomInputLabel>
                    <Select
                      labelId="type-label"
                      id="type"
                      name="type"
                      value={newChannel.type}
                      onChange={handleInputChange}
                      // label="Type"
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem value={ShopPaymentChannelDto.type.BANK_ACCOUNT}>
                        บัญชีธนาคาร
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.type.PROMPT_PAY}>
                        พร้อมเพย์
                      </MenuItem>
                      <MenuItem
                        value={ShopPaymentChannelDto.type.PROMPT_PAY_QR}
                      >
                        พร้อมเพย์ (QR)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.type.AUTOMATIC_QR}>
                        QR Code (Payment Gateway)
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth></FormControl>
                </Stack>
                <Stack direction={{ lg: "row" }} gap={2}>
                  <FormControl
                    fullWidth
                    sx={{
                      display:
                        newChannel.type ===
                          ShopPaymentChannelDto.type.BANK_ACCOUNT
                          ? ""
                          : "none",
                    }}
                  >
                    <CustomInputLabel id="type-label">ธนาคาร</CustomInputLabel>
                    <Select
                      labelId="bank-code-label"
                      id="bangCode"
                      name="bangCode"
                      value={newChannel.bankCode || ""}
                      onChange={handleBangCodeChange}
                      size="small"
                      variant="outlined"
                      placeholder="เลือกธนาคาร"
                    >
                      <MenuItem value={ShopPaymentChannelDto.bankCode.BBL}>
                        ธนาคารกรุงเทพ (BBL)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.bankCode.BAY}>
                        ธนาคารกรุงศรีอยุธยา (BAY)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.bankCode.KBANK}>
                        ธนาคารกสิกร (KBANK)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.bankCode.SCB}>
                        ธนาคารไทยพาณิชย์ (SCB)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.bankCode.KTB}>
                        ธนาคารกรุงไทย (KTB)
                      </MenuItem>
                      <MenuItem value={ShopPaymentChannelDto.bankCode.TTB}>
                        ธนาคารทีเอ็มบีธนชาต (TTB)
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {newChannel.type ===
                    ShopPaymentChannelDto.type.AUTOMATIC_QR ? (
                    <Stack
                      sx={{
                        width: "100%",
                      }}
                    >
                      <CustomInputLabel id="type-label">
                        Integration Token
                      </CustomInputLabel>
                      <TextField
                        fullWidth
                        id="integrationToken"
                        name="integrationToken"
                        value={newChannel.integrationToken}
                        onChange={handleInputChange}
                        size="small"
                        variant="outlined"
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Stack>
                  ) : newChannel.type ===
                    ShopPaymentChannelDto.type.PROMPT_PAY_QR ? (
                    newChannelImage ? (
                      <Stack>
                        <CustomInputLabel id="type-label">
                          รูป QR Cod
                        </CustomInputLabel>
                        <Stack direction={"row"} sx={{ alignItems: "center" }}>
                          <Typography>{newChannelImage.name}</Typography>
                          <IconButton
                            onClick={() => {
                              // setFile(null);
                              setNewChannelImage(null);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </Stack>
                    ) : (
                      <FormControl fullWidth>
                        <CustomInputLabel id="type-label">
                          รูป QR Code
                        </CustomInputLabel>
                        {newChannelImage && (
                          <Avatar
                            variant="square"
                            sx={{ width: 100, height: 100 }}
                            src={URL.createObjectURL(newChannelImage)}
                          />
                        )}
                        <Button
                          component="label"
                          role={undefined}
                          variant="outlined"
                          tabIndex={-1}
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                        >
                          อัพโหลดรูป QR Code
                          <VisuallyHiddenInput
                            type="file"
                            onChange={async (event) => {
                              const file = event.target.files?.[0]; // Add null check
                              console.log("file-name", file?.name);
                              if (file) {
                                setNewChannelImage(file);
                                // await uploadFile({ file });
                              }
                            }}
                          />
                        </Button>
                      </FormControl>
                    )
                  ) : (
                    <FormControl fullWidth>
                      <CustomInputLabel id="type-label">
                        {newChannel.type ===
                          ShopPaymentChannelDto.type.BANK_ACCOUNT
                          ? "เลขที่บัญชี"
                          : "บัญชีพร้อมเพย์"}
                      </CustomInputLabel>
                      <TextField
                        fullWidth
                        id="accountId"
                        name="accountId"
                        // label="Account ID"
                        value={newChannel.accountId}
                        onChange={handleInputChange}
                        size="small"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                </Stack>
                <FormControl fullWidth>
                  <CustomInputLabel id="type-label">
                    {"ชื่อบัญชี"}
                  </CustomInputLabel>
                  <TextField
                    fullWidth
                    id="accountName"
                    name="accountName"
                    // label="Account ID"
                    value={newChannel.accountName}
                    onChange={(e) => {
                      setNewChannel((prev) => ({
                        ...prev,
                        accountName: e.target.value,
                      }));
                    }}
                    size="small"
                    variant="outlined"
                  />
                </FormControl>
              </Stack>
              <Box mt={2} sx={{ width: "100%" }}>
                <Button
                  onClick={handleAddChannel}
                  variant="contained"
                  color="primary"
                >
                  เพิ่มช่องทางจ่ายเงิน
                </Button>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Spin>
  );
};
