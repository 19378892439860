import { DefaultError, QueryClient, useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

const useSyncMutation = <TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
    options: UseMutationOptions<TData, TError, TVariables, TContext>, queryClient?: QueryClient
): UseMutationResult<TData, TError, TVariables, TContext> => {
    const mutationResults = useMutation(options, queryClient);

    return {
        ...mutationResults,
        mutate: (...params: [TVariables]) => {
            if (!mutationResults.isPending) {
                mutationResults.mutate(...params);
            }
        }
    };
};

export default useSyncMutation;