/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FinancierAvatarUploadConfirmRequest } from '../models/FinancierAvatarUploadConfirmRequest';
import type { FinancierAvatarUploadUrlRequest } from '../models/FinancierAvatarUploadUrlRequest';
import type { FinancierDto } from '../models/FinancierDto';
import type { FinancierPaymentChannelCreateRequest } from '../models/FinancierPaymentChannelCreateRequest';
import type { FinancierPaymentChannelDto } from '../models/FinancierPaymentChannelDto';
import type { FinancierPaymentChannelQrCodeUploadConfirmRequest } from '../models/FinancierPaymentChannelQrCodeUploadConfirmRequest';
import type { FinancierPaymentChannelQrCodeUploadUrlRequest } from '../models/FinancierPaymentChannelQrCodeUploadUrlRequest';
import type { FinancierUserInvitationRequest } from '../models/FinancierUserInvitationRequest';
import type { FinancierUserRemoveRequest } from '../models/FinancierUserRemoveRequest';
import type { FinancierUserResponse } from '../models/FinancierUserResponse';
import type { PaginatedFinancierSearchDto } from '../models/PaginatedFinancierSearchDto';
import type { UploadConfirmResponse } from '../models/UploadConfirmResponse';
import type { UploadUrlResponse } from '../models/UploadUrlResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class FinancierService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns FinancierDto OK
     * @throws ApiError
     */
    public updateFinancier({
        requestBody,
    }: {
        requestBody: FinancierDto,
    }): CancelablePromise<FinancierDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/financier/v2',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public createFinancierUserInvitation({
        requestBody,
    }: {
        requestBody: FinancierUserInvitationRequest,
    }): CancelablePromise<FinancierUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/users/invitation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public createFinancierPaymentChannel({
        requestBody,
    }: {
        requestBody: FinancierPaymentChannelCreateRequest,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/payment-channels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public enableFinancierPaymentChannel({
        paymentChannelId,
    }: {
        paymentChannelId: string,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/payment-channels/{paymentChannelId}/enable',
            path: {
                'paymentChannelId': paymentChannelId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public disableFinancierPaymentChannel({
        paymentChannelId,
    }: {
        paymentChannelId: string,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/payment-channels/{paymentChannelId}/disable',
            path: {
                'paymentChannelId': paymentChannelId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getFinancierQrCodeUploadUrl({
        requestBody,
    }: {
        requestBody: FinancierPaymentChannelQrCodeUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/payment-channels/qr-code-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public confirmFinancierQrCodeUpload({
        requestBody,
    }: {
        requestBody: FinancierPaymentChannelQrCodeUploadConfirmRequest,
    }): CancelablePromise<FinancierPaymentChannelDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/payment-channels/qr-code-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadUrlResponse OK
     * @throws ApiError
     */
    public getFinancierAvatarUploadUrl({
        requestBody,
    }: {
        requestBody: FinancierAvatarUploadUrlRequest,
    }): CancelablePromise<UploadUrlResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/avatar-upload-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns UploadConfirmResponse OK
     * @throws ApiError
     */
    public confirmShopAvatarUpload1({
        requestBody,
    }: {
        requestBody: FinancierAvatarUploadConfirmRequest,
    }): CancelablePromise<UploadConfirmResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/financier/v2/avatar-upload-confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierDto OK
     * @throws ApiError
     */
    public getFinancierUsers({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<FinancierDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/financier/v2/{financierId}',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public getFinancierIdUsers({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<Array<FinancierUserResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/financier/v2/{financierId}/users',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierPaymentChannelDto OK
     * @throws ApiError
     */
    public getFinancierPaymentChannels({
        financierId,
    }: {
        financierId: string,
    }): CancelablePromise<Array<FinancierPaymentChannelDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/financier/v2/{financierId}/payment-channels',
            path: {
                'financierId': financierId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierSearchDto OK
     * @throws ApiError
     */
    public getFinancierUsers1({
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierSearchDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/financier/v2/search',
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public removeFinancierUser({
        requestBody,
    }: {
        requestBody: FinancierUserRemoveRequest,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/financier/v2/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
