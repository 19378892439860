import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { QueryKey } from ".";
import { useBackendApiClient } from "../motalvip-apis";
import { BackendApiClient } from "../motalvip-apis/generated";

export const useViewInstallmentPaymentSumReport = (
  params: Parameters<
    BackendApiClient["installmentPayment"]["sumByPaymentChannel"]
  >[0]
) => {
  const apiClient = useBackendApiClient();
  return useQuery({
    queryKey: [QueryKey.LOANS, params, "installmentPaymentsReportSum"],
    queryFn: () => apiClient.installmentPayment.sumByPaymentChannel(params),
    placeholderData: keepPreviousData,
  });
};
