import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { InstallmentPlanMonthlyAggregation } from "../../client/motalvip-apis/generated";
import { useShopDashboardQuery } from "../../client/query-client/useShopDashboardQuery";
import { RevenueWidget } from "../../component/dashboard/widget/RevenueWidget";


export const ShopDashboardScreen = () => {
    const { shopId } = useParams()
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [selectedMonth, setSelectedMonth] = useState<number>(dayjs().get('month') + 1)
    const currencyFormat = Intl.NumberFormat('en-Us', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const shopDashboardQuery = useShopDashboardQuery(shopId || '', { year: selectedYear, month: selectedMonth })
    const [totalRows, setTotalRows] = useState<InstallmentPlanMonthlyAggregation[]>([])

    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedYear(event.target.value as number);
    }

    useEffect(() => {
        shopDashboardQuery.refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, selectedMonth])
    useEffect(() => {
        if (shopDashboardQuery.data?.installmentPlanMonthlySummaries) {
            const summaryRow = shopDashboardQuery.data?.installmentPlanMonthlySummaries.reduce((acc, current) => {
                return {
                    month: 0,
                    installmentPlanCount: (acc.installmentPlanCount || 0) + (current.installmentPlanCount || 0),
                    installmentPlanCountStatusNormal: (acc.installmentPlanCountStatusNormal || 0) + (current.installmentPlanCountStatusNormal || 0),
                    installmentPlanCountStatusClosed: (acc.installmentPlanCountStatusClosed || 0) + (current.installmentPlanCountStatusClosed || 0),
                    installmentPlanCountStatusRefund: (acc.installmentPlanCountStatusRefund || 0) + (current.installmentPlanCountStatusRefund || 0),
                    installmentPlanCountStatusFollowingUp: (acc.installmentPlanCountStatusFollowingUp || 0) + (current.installmentPlanCountStatusFollowingUp || 0),
                    installmentPlanCountStatusCannotContactCustomer: (acc.installmentPlanCountStatusCannotContactCustomer || 0) + (current.installmentPlanCountStatusCannotContactCustomer || 0),
                    installmentPlanCountStatusReported: (acc.installmentPlanCountStatusReported || 0) + (current.installmentPlanCountStatusReported || 0),
                    installmentPlanCountStatusNonPerforming: (acc.installmentPlanCountStatusNonPerforming || 0) + (current.installmentPlanCountStatusNonPerforming || 0),
                    installmentPlanCountCancel: (acc.installmentPlanCountCancel || 0) + (current.installmentPlanCountCancel || 0),
                    totalInstallmentPlanAmount: (acc.totalInstallmentPlanAmount || 0) + (current.totalInstallmentPlanAmount || 0),
                    totalDownPaymentAmount: (acc.totalDownPaymentAmount || 0) + (current.totalDownPaymentAmount || 0),
                    totalTermPaidDueAmount: (acc.totalTermPaidDueAmount || 0) + (current.totalTermPaidDueAmount || 0),
                    totalTermPaidFineAmount: (acc.totalTermPaidFineAmount || 0) + (current.totalTermPaidFineAmount || 0),
                    totalIncome: (acc.totalIncome || 0) + (current.totalIncome || 0),
                    totalPurchaseCost: (acc.totalPurchaseCost || 0) + (current.totalPurchaseCost || 0),
                    totalCommission: (acc.totalCommission || 0) + (current.totalCommission || 0),
                    totalOtherCost: (acc.totalOtherCost || 0) + (current.totalOtherCost || 0),
                    totalCost: (acc.totalCost || 0) + (current.totalCost || 0),
                    totalProfit: (acc.totalProfit || 0) + (current.totalProfit || 0),
                }
            })
            setTotalRows([...(shopDashboardQuery.data?.installmentPlanMonthlySummaries || []), summaryRow, shopDashboardQuery.data.totalSummary || {}])
        }
    }, [shopDashboardQuery.data])

    const installmentPlanCountColumns = useMemo<MRT_ColumnDef<InstallmentPlanMonthlyAggregation>[]>(
        () => [
            {
                id: 'month',
                accessorFn: (row: InstallmentPlanMonthlyAggregation) => {
                    return <Typography>{row.month === 0 ? 'รวม' : row.month === 100 ? 'ทั้งหมด' : row.month}</Typography>
                },
                header: 'เดือน',
                enableHiding: false,
                enableSorting: false,
                grow: false,
                size: 40,
                enableResizing: false,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Header: ({ column }) => (
                    <Box width={40}>
                        <Typography color='black'>{column.columnDef.header}</Typography>
                    </Box>
                ),
            },
            {
                id: 'status',
                header: 'จำนวนสินเชื่อ',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalInstallmentPlanStatusNormal',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusNormal}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusNormal || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'กำลังผ่อน',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusClosed',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusClosed}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusClosed || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ปิดยอด',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusRefund',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusRefund}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusRefund || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'คืนเงิน',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusFollowingUp',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusFollowingUp}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusFollowingUp || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ติดตาม',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusCannotContactCustomer',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusCannotContactCustomer}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusCannotContactCustomer || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ติดต่อไม่ได้',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlanStatusReported',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusReported}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusReported || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'แจ้งความ',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                    {
                        id: 'totalInstallmentPlanStatusNonPerforming',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountStatusNonPerforming}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountStatusNonPerforming || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'หนี้เสีย',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                    {
                        id: 'totalInstallmentPlanCancel',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Stack direction={'row'} gap={1} justifyContent='flex-end'>
                                <Typography>
                                    {row.installmentPlanCountCancel}
                                </Typography>
                                <Typography color='#aaaaaa'>
                                    {`(${((row.installmentPlanCountCancel || 0) * 100 / (row.installmentPlanCount || 1)).toFixed(1)}%)`}
                                </Typography>
                            </Stack>,
                        header: 'ยกเลิก',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,

                    },
                    {
                        id: 'totalInstallmentPlan',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography sx={{ fontWeight: '500', color: '#0b57d0' }}>{row.installmentPlanCount}</Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        grow: false,
                        enableSorting: false,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                        size: 120,
                    },
                ]
            },
        ],
        [],
    );

    const installmentPlanAmountColumns = useMemo<MRT_ColumnDef<InstallmentPlanMonthlyAggregation>[]>(
        () => [
            {
                id: 'month',
                accessorFn: (row: InstallmentPlanMonthlyAggregation) => {
                    return <Typography>{row.month === 0 ? 'รวม' : row.month === 100 ? 'ทั้งหมด' : row.month}</Typography>
                },
                header: 'เดือน',
                enableHiding: false,
                enableSorting: false,
                grow: false,
                size: 80,
                enableResizing: false,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
                Header: ({ column }) => (
                    <Box width={40}>
                        <Typography color='black'>{column.columnDef.header}</Typography>
                    </Box>
                ),
            },
            {
                id: 'totalInstallmentPlanAmount',
                accessorFn: (row: InstallmentPlanMonthlyAggregation) => {
                    return <Typography sx={{ fontWeight: '500', color: '#0b57d0' }}>{currencyFormat.format(row.totalInstallmentPlanAmount || 0)}</Typography>
                }, //simple recommended way to define a column
                header: 'ยอดสินเชื่อ',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 130,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                id: 'income',
                header: 'รายรับ',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalDownPaymentAmount',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >{currencyFormat.format(row.totalDownPaymentAmount || 0)}</Typography>,
                        header: 'ยอดดาวน์',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalTermPaidDueAmount',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >{currencyFormat.format(row.totalTermPaidDueAmount || 0)}</Typography>,
                        header: 'ยอดค่างวดจ่ายแล้ว',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalTermPaidFineAmount',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >
                                {currencyFormat.format(row.totalTermPaidFineAmount || 0)}
                            </Typography>,
                        header: 'ยอดค่าปรับจ่ายแล้ว',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalIncome',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography sx={{ fontWeight: '500', color: '#43a047' }}>
                                {currencyFormat.format(row.totalIncome || 0)}
                            </Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    }
                ]
            },
            {
                id: 'cost',
                header: 'ต้นทุน',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                columns: [
                    {
                        id: 'totalPurchaseCost',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >
                                {currencyFormat.format(row.totalPurchaseCost || 0)}
                            </Typography>,
                        header: 'ค่าเครื่อง',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalCommission',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >
                                {currencyFormat.format(row.totalCommission || 0)}
                            </Typography>,
                        header: 'ค่าคอม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalOtherCost',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography >
                                {currencyFormat.format(row.totalOtherCost || 0)}
                            </Typography>,
                        header: 'ค่าอื่นๆ',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    },
                    {
                        id: 'totalCost',
                        accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                            <Typography sx={{ fontWeight: '500', color: '#f44336' }} >
                                {currencyFormat.format(row.totalCost || 0)}
                            </Typography>,
                        header: 'รวม',
                        enableHiding: false,
                        enablePinning: false,
                        enableSorting: false,
                        size: 130,
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'right',
                        },
                    }
                ]
            },
            {
                id: 'totalProfit',
                Header: ({ column }) => (
                    <Typography p={1} color='white'>{column.columnDef.header}</Typography>
                ),
                header: 'สรุป',
                enableHiding: false,
                enablePinning: false,
                enableSorting: false,
                size: 130,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                columns: [{
                    id: 'totalProfit',
                    accessorFn: (row: InstallmentPlanMonthlyAggregation) =>
                        <Typography sx={{ fontWeight: '500', color: (row.totalProfit || 0) > 0 ? '#43a047' : (row.totalProfit || 0) < 0 ? '#f44336' : 'black' }}>
                            {currencyFormat.format(row.totalProfit || 0)}
                        </Typography>,
                    header: 'กำไร / ขาดทุน',
                    enableHiding: false,
                    enablePinning: false,
                    enableSorting: false,
                    size: 130,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'right',
                    },
                }]
            },
        ],
        [currencyFormat],
    );



    const installmentPlanCountTable = useMaterialReactTable({
        columns: installmentPlanCountColumns,
        data: totalRows,
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        state: {
            isLoading: shopDashboardQuery.isFetching,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                left: ['month'],
            },
        },
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500',
                padding: '8px',
            },
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(even):not([data-selected="true"])) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                padding: '2px 8px',
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },

    });

    const installmentPlanAmountTable = useMaterialReactTable({
        columns: installmentPlanAmountColumns,
        data: totalRows,
        layoutMode: 'semantic',
        enablePagination: false,
        enableColumnPinning: true,
        enableGlobalFilter: false,
        enableColumnFilters: false,
        enableSorting: true,
        enableHiding: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnVirtualization: false,
        enableColumnOrdering: false,
        enableBottomToolbar: false,
        positionGlobalFilter: 'left',
        enableTopToolbar: false,
        manualSorting: true,
        paginationDisplayMode: 'pages',
        state: {
            isLoading: shopDashboardQuery.isFetching,
        },
        localization: {
            rowsPerPage: "จำนวนต่อหน้า"
        },
        manualPagination: true,
        initialState: {
            showGlobalFilter: false,
            columnPinning: {
                left: ['month'],
            },
        },
        positionActionsColumn: 'last',
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: '', //change header text
                size: 12, //make actions column wider
            },
        },
        muiTableHeadCellProps: {
            sx: {
                borderRight: '1px solid #e0e0e0', //add a border between columns
                backgroundColor: '#455a64',
                color: 'white',
                fontWeight: '500',
                padding: '8px',
            },
        },
        muiTableBodyProps: {
            sx: {
                '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td': {
                    backgroundColor: 'grey.100',
                },
            },
        },
        muiTableBodyCellProps: {
            sx: {
                padding: '2px 8px',
                borderRight: '1px solid #eeeeee', //add a border between columns
            },
        },

    });

    return (
        <Box p={2}>
            <Box sx={{ width: { xs: '90%', md: '40%' } }}>
                <Stack direction={{ md: 'row' }} gap={2} >
                    <TextField
                        sx={{ backgroundColor: 'white' }}
                        label="ปี"
                        value={selectedYear}
                        onChange={handleYearChange}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <FormControl sx={{ width: '300px' }} fullWidth>
                        <InputLabel id="demo-simple-select-label">เดือน</InputLabel>
                        <Select
                            sx={{ backgroundColor: 'white' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedMonth}
                            onChange={(e) => setSelectedMonth(e.target.value as number)}
                            label="เดือน"
                        >
                            <MenuItem value={1}>มกราคม</MenuItem>
                            <MenuItem value={2}>กุมภาพันธ์</MenuItem>
                            <MenuItem value={3}>มีนาคม</MenuItem>
                            <MenuItem value={4}>เมษายน</MenuItem>
                            <MenuItem value={5}>พฤษภาคม</MenuItem>
                            <MenuItem value={6}>มิถุนายน</MenuItem>
                            <MenuItem value={7}>กรกฎาคม</MenuItem>
                            <MenuItem value={8}>สิงหาคม</MenuItem>
                            <MenuItem value={9}>กันยายน</MenuItem>
                            <MenuItem value={10}>ตุลาคม</MenuItem>
                            <MenuItem value={11}>พฤศจิกายน</MenuItem>
                            <MenuItem value={12}>ธันวาคม</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    สรุปยอดแยกเดือน
                </Typography>
                <Stack gap={4}>
                    <MaterialReactTable table={installmentPlanAmountTable} />
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    สรุปสถานะแยกเดือน
                </Typography>
                <Stack gap={4}>
                    <MaterialReactTable table={installmentPlanCountTable} />
                </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรายเดือน ${selectedMonth} ปี ${selectedYear}`}
                </Typography>
                {shopDashboardQuery.data?.monthlyRevenue && <RevenueWidget installmentPlanRevenue={shopDashboardQuery.data.monthlyRevenue} />}
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรายปี ${selectedYear}`}
                </Typography>
                {shopDashboardQuery.data?.yearlyRevenue && <RevenueWidget installmentPlanRevenue={shopDashboardQuery.data.yearlyRevenue} />}
            </Box>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" sx={{ mt: 4, py: 2 }}>
                    {`กระแสรวมทั้งหมด`}
                </Typography>
                {shopDashboardQuery.data?.totalRevenue && <RevenueWidget installmentPlanRevenue={shopDashboardQuery.data.totalRevenue} />}
            </Box>
        </Box >
    )
}