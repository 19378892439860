import { useMutation } from "@tanstack/react-query";

const uploadFile = async ({ file, uploadUrl }: { file: File; uploadUrl: string }) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", uploadUrl, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(file.name);
            } else {
                reject(new Error(`Upload failed for ${file.name}: ${xhr.responseText}`));
            }
        };
        xhr.onerror = () => reject(new Error(`Network error on upload ${file.name}`));
        xhr.send(file);
    });
};

export const useUploadFileMutation = () => {
    return useMutation({
        mutationFn: uploadFile
    });
}
