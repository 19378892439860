/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstallmentNote } from './InstallmentNote';
import type { InstallmentPaymentDto } from './InstallmentPaymentDto';
import type { InstallmentPlanDocumentDto } from './InstallmentPlanDocumentDto';
import type { InstallmentTerm } from './InstallmentTerm';
import type { ProductDto } from './ProductDto';
import type { ProfileDto } from './ProfileDto';
export type InstallmentPlanDto = {
    creationToken?: string;
    id?: string;
    shopId: string;
    profile: ProfileDto;
    product: ProductDto;
    registrationDate: string;
    registrationStatus?: InstallmentPlanDto.registrationStatus;
    registrationRejectReason?: string;
    sequenceNumber?: number;
    referenceId?: string;
    status?: InstallmentPlanDto.status;
    fundSource: InstallmentPlanDto.fundSource;
    activeTermNumber?: number;
    activeTermDueDate?: string;
    activeInstallmentPaymentId?: string;
    activeInstallmentPaymentAmount?: number;
    activeInstallmentPaymentDate?: string;
    commissionAmount?: number;
    totalAmount?: number;
    downPaymentAmount: number;
    downPaymentChannelId?: string;
    downPaymentDate: string;
    installmentTotalAmount?: number;
    installmentCount: number;
    installmentAmount: number;
    installmentStartDate: string;
    installmentFrequency: number;
    installmentFrequencyType: InstallmentPlanDto.installmentFrequencyType;
    otherCostAmount?: number;
    notes?: Array<InstallmentNote>;
    documents?: Array<InstallmentPlanDocumentDto>;
    installmentTerms?: Array<InstallmentTerm>;
    payments?: Array<InstallmentPaymentDto>;
    activeDueDateDiff?: number;
    appointmentDateDiff?: number;
    createdByUserId?: string;
    createdByUserName?: string;
    createdByLastName?: string;
    valid?: boolean;
};
export namespace InstallmentPlanDto {
    export enum registrationStatus {
        PENDING_CUSTOMER_INFO = 'PENDING_CUSTOMER_INFO',
        PENDING_CUSTOMER_DOCUMENTS = 'PENDING_CUSTOMER_DOCUMENTS',
        PENDING_LOAN_AND_DEVICE_INFO = 'PENDING_LOAN_AND_DEVICE_INFO',
        PENDING_APPROVAL = 'PENDING_APPROVAL',
        APPROVED = 'APPROVED',
        REJECTED = 'REJECTED',
        COMPLETED = 'COMPLETED',
        CANCEL = 'CANCEL',
    }
    export enum status {
        DRAFT = 'DRAFT',
        PENDING_CONTRACT_DOCUMENTS = 'PENDING_CONTRACT_DOCUMENTS',
        PENDING_CONFIRM_DOWN_PAYMENT = 'PENDING_CONFIRM_DOWN_PAYMENT',
        NORMAL = 'NORMAL',
        CLOSED = 'CLOSED',
        REFUND = 'REFUND',
        FOLLOWING_UP = 'FOLLOWING_UP',
        CANNOT_CONTACT_CUSTOMER = 'CANNOT_CONTACT_CUSTOMER',
        NON_PERFORMING = 'NON_PERFORMING',
        REPORTED = 'REPORTED',
        CANCEL = 'CANCEL',
    }
    export enum fundSource {
        SHOP = 'SHOP',
        FINANCE = 'FINANCE',
    }
    export enum installmentFrequencyType {
        DAY = 'DAY',
        MONTH = 'MONTH',
    }
}

