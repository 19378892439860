import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import React, { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentDto } from "../../../client/motalvip-apis/generated";
import {
  useGeneratePaymentCsvMutation,
  usePaymentsViewQuery,
} from "../../../client/query-client";
import { formatShortReadableThaiDate } from "../../../util/DateUtil";
import { downloadFile } from "../../../util/FileUtils";
import { useDebouncedValue } from "../../../util/useDebouncedValue";

function mapStatusToText(status?: string): ReactNode {
  switch (status) {
    case "SUCCESS":
      return (
        <p
          style={{
            color: "green",
          }}
        >
          สำเร็จ
        </p>
      );
    case "CANCEL":
      return (
        <p
          style={{
            color: "red",
          }}
        >
          ยกเลิก
        </p>
      );
    case "PENDING":
      return (
        <p
          style={{
            color: "orange",
          }}
        >
          รอดำเนินการ
        </p>
      );
    case "COMPLETED":
      return (
        <p
          style={{
            color: "green",
          }}
        >
          เสร็จสิ้น
        </p>
      );
    case "OVERDUE":
      return (
        <p
          style={{
            color: "red",
          }}
        >
          เลยกำหนด
        </p>
      );
    default:
      return (
        <p
          style={{
            color: "gray",
          }}
        >
          ไม่ทราบสถานะ
        </p>
      );
  }
}

export const PaymentsViewScreen = () => {
  const { shopId } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebouncedValue(search, 250);
  const [sortBy, setSortBy] = useState<keyof PaymentDto>("createdAt");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const { data } = usePaymentsViewQuery({
    shopId: shopId ?? "",
    from: startDate?.toISOString() ?? "",
    to: endDate?.toISOString() ?? "",
    direction: sortDirection.toUpperCase(),
    page,
    search: debouncedSearch,
    limit: rowsPerPage,
    sortBy,
  });

  const { mutateAsync: generateCsvReportAsync } =
    useGeneratePaymentCsvMutation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property: keyof PaymentDto) => {
    const isAsc = sortBy === property && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  const handleGenerateCsv = async () => {
    setIsGeneratingCsv(true);
    try {
      const result = await generateCsvReportAsync({
        from: startDate?.toISOString() ?? "",
        to: endDate?.toISOString() ?? "",
        shopId: shopId ?? "",
        direction: sortDirection.toUpperCase(),
        page,
        search: debouncedSearch,
        limit: rowsPerPage,
        sortBy,
      });

      downloadFile(result.csvReportUrl, "payment-report.csv");
    } finally {
      setIsGeneratingCsv(false);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        display="flex"
        sx={{
          gap: "16px",
          alignItems: "Center",
          flexDirection: { sm: "column", md: "row" },
        }}
        mb={2}
        mt={2}
      >
        <TextField
          sx={{
            margin: 0,
            padding: 0,
          }}
          label="ค้นหาชื่อหรืออีเมลลูกค้า"
          value={search}
          onChange={handleSearchChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
          <DatePicker
            sx={{
              width: { sm: "100%", md: "fit-content" },
            }}
            label="วันที่เริ่มต้น"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <DatePicker
            sx={{
              width: { sm: "100%", md: "fit-content" },
            }}
            label="วันที่สิ้นสุด"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
        </LocalizationProvider>
      </Box>

      <Button
        variant="contained"
        color="primary"
        disabled={
          (data?.pagination?.totalElements ?? 0) === 0 || isGeneratingCsv
        }
        onClick={handleGenerateCsv}
        sx={{ mb: 2 }}
      >
        {isGeneratingCsv ? "กำลังดาวน์โหลด..." : "ดาวน์โหลดรายงาน Excel"}
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={sortBy === "createdAt" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "createdAt"}
                  direction={sortBy === "createdAt" ? sortDirection : "asc"}
                  onClick={() => handleSort("createdAt")}
                >
                  วันจ่าย
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "amount" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "amount"}
                  direction={sortBy === "amount" ? sortDirection : "asc"}
                  onClick={() => handleSort("amount")}
                >
                  จำนวนเงิน
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "customerName" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "customerName"}
                  direction={sortBy === "customerName" ? sortDirection : "asc"}
                  onClick={() => handleSort("customerName")}
                >
                  ชื่อลูกค้า
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  sortBy === "customerEmail" ? sortDirection : false
                }
              >
                <TableSortLabel
                  active={sortBy === "customerEmail"}
                  direction={sortBy === "customerEmail" ? sortDirection : "asc"}
                  onClick={() => handleSort("customerEmail")}
                >
                  อีเมลล์ลูกค้า
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={sortBy === "status" ? sortDirection : false}
              >
                <TableSortLabel
                  active={sortBy === "status"}
                  direction={sortBy === "status" ? sortDirection : "asc"}
                  onClick={() => handleSort("status")}
                >
                  สถานะ
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.content?.length ? (
              data.content.map((payment: PaymentDto) => (
                <TableRow key={payment.id}>
                  <TableCell>
                    {formatShortReadableThaiDate(new Date(payment.createdAt))}
                  </TableCell>
                  <TableCell>{payment?.amount?.toFixed(2) ?? 0}</TableCell>
                  <TableCell>{payment.customerName}</TableCell>
                  <TableCell>{payment.customerEmail}</TableCell>
                  <TableCell>{mapStatusToText(payment.status)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Box sx={{ textAlign: "center", p: 3 }}>
                    <Typography variant="h6">ไม่พบรายการจ่ายเงิน</Typography>
                    <Typography variant="body2">
                      กรุณาลองค้นหาอีกครั้ง หรือตรวจสอบเงื่อนไขการค้นหา
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data?.pagination?.totalElements ?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
