/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminLoanCalculateInstallmentRequest } from '../models/AdminLoanCalculateInstallmentRequest';
import type { AdminShopUpdateRequest } from '../models/AdminShopUpdateRequest';
import type { FinancierUserInvitationOwnerRequest } from '../models/FinancierUserInvitationOwnerRequest';
import type { FinancierUserResponse } from '../models/FinancierUserResponse';
import type { LoanSearchRequest } from '../models/LoanSearchRequest';
import type { MasterDeviceDto } from '../models/MasterDeviceDto';
import type { PaginatedFinancierSearchDto } from '../models/PaginatedFinancierSearchDto';
import type { PaginatedInstallmentPlanDto } from '../models/PaginatedInstallmentPlanDto';
import type { PaginatedMasterDeviceModelDto } from '../models/PaginatedMasterDeviceModelDto';
import type { PaginatedShop } from '../models/PaginatedShop';
import type { PaginatedUser } from '../models/PaginatedUser';
import type { Shop } from '../models/Shop';
import type { ShopDto } from '../models/ShopDto';
import type { ShopSearchRequest } from '../models/ShopSearchRequest';
import type { User } from '../models/User';
import type { UserSearchRequest } from '../models/UserSearchRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any OK
     * @throws ApiError
     */
    public updateMasterDevice({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: MasterDeviceDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/admin/v2/master/devices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public deleteMasterDevice({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/admin/v2/master/devices/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedUser OK
     * @throws ApiError
     */
    public searchUsers({
        requestBody,
    }: {
        requestBody: UserSearchRequest,
    }): CancelablePromise<PaginatedUser> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/users/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedShop OK
     * @throws ApiError
     */
    public getShops({
        requestBody,
    }: {
        requestBody: ShopSearchRequest,
    }): CancelablePromise<PaginatedShop> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/shops/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedMasterDeviceModelDto OK
     * @throws ApiError
     */
    public getMasterDevices({
        limit,
        page,
        model,
        brand,
    }: {
        limit: number,
        page: number,
        model?: string,
        brand?: string,
    }): CancelablePromise<PaginatedMasterDeviceModelDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v2/master/devices',
            query: {
                'model': model,
                'brand': brand,
                'limit': limit,
                'page': page,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns any OK
     * @throws ApiError
     */
    public createMasterDevice({
        requestBody,
    }: {
        requestBody: MasterDeviceDto,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/master/devices',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedInstallmentPlanDto OK
     * @throws ApiError
     */
    public searchLoans1({
        requestBody,
    }: {
        requestBody: LoanSearchRequest,
    }): CancelablePromise<PaginatedInstallmentPlanDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/loans/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns string OK
     * @throws ApiError
     */
    public calculateLoanInstallment({
        requestBody,
    }: {
        requestBody: AdminLoanCalculateInstallmentRequest,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/loans/calculate-installment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns FinancierUserResponse OK
     * @throws ApiError
     */
    public createFinancierUserInvitation1({
        requestBody,
    }: {
        requestBody: FinancierUserInvitationOwnerRequest,
    }): CancelablePromise<FinancierUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/v2/financier/invitation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns Shop OK
     * @throws ApiError
     */
    public updateShop1({
        requestBody,
    }: {
        requestBody: AdminShopUpdateRequest,
    }): CancelablePromise<Shop> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/v2/shops',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns User OK
     * @throws ApiError
     */
    public getProfile(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v2/users/me',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns ShopDto OK
     * @throws ApiError
     */
    public getShopById1({
        shopId,
    }: {
        shopId: string,
    }): CancelablePromise<ShopDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v2/shops/{shopId}',
            path: {
                'shopId': shopId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedFinancierSearchDto OK
     * @throws ApiError
     */
    public searchFinancier({
        search,
        sortBy = 'createdAt',
        sort = 'desc',
        page,
        limit = 10,
    }: {
        search?: string,
        sortBy?: string,
        sort?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedFinancierSearchDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/v2/financiers/search',
            query: {
                'search': search,
                'sortBy': sortBy,
                'sort': sort,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
