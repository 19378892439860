/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Shop } from './Shop';
export type Financier = {
    id?: string;
    createdAt?: string;
    updatedAt?: string;
    name?: string;
    address?: string;
    facebookUrl?: string;
    lineId?: string;
    status?: Financier.status;
    avatarObjectKey?: string;
    requestAt?: string;
    expireAt?: string;
    capitalAmount?: number;
    incomeAmount?: number;
    expenseAmount?: number;
    approvedAt?: string;
    shops?: Array<Shop>;
};
export namespace Financier {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

