/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdminService } from './services/AdminService';
import { ClerkWebhookService } from './services/ClerkWebhookService';
import { FinancierService } from './services/FinancierService';
import { HistoryService } from './services/HistoryService';
import { InstallmentPaymentService } from './services/InstallmentPaymentService';
import { InstallmentPlanService } from './services/InstallmentPlanService';
import { LoanRegistrationService } from './services/LoanRegistrationService';
import { PaymentService } from './services/PaymentService';
import { ProfileService } from './services/ProfileService';
import { PublicService } from './services/PublicService';
import { ReportService } from './services/ReportService';
import { ShopService } from './services/ShopService';
import { TokenService } from './services/TokenService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class BackendApiClient {
    public readonly admin: AdminService;
    public readonly clerkWebhook: ClerkWebhookService;
    public readonly financier: FinancierService;
    public readonly history: HistoryService;
    public readonly installmentPayment: InstallmentPaymentService;
    public readonly installmentPlan: InstallmentPlanService;
    public readonly loanRegistration: LoanRegistrationService;
    public readonly payment: PaymentService;
    public readonly profile: ProfileService;
    public readonly public: PublicService;
    public readonly report: ReportService;
    public readonly shop: ShopService;
    public readonly token: TokenService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:8080',
            VERSION: config?.VERSION ?? '0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.clerkWebhook = new ClerkWebhookService(this.request);
        this.financier = new FinancierService(this.request);
        this.history = new HistoryService(this.request);
        this.installmentPayment = new InstallmentPaymentService(this.request);
        this.installmentPlan = new InstallmentPlanService(this.request);
        this.loanRegistration = new LoanRegistrationService(this.request);
        this.payment = new PaymentService(this.request);
        this.profile = new ProfileService(this.request);
        this.public = new PublicService(this.request);
        this.report = new ReportService(this.request);
        this.shop = new ShopService(this.request);
        this.token = new TokenService(this.request);
        this.user = new UserService(this.request);
    }
}

