/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CsvReportGenerationResult } from '../models/CsvReportGenerationResult';
import type { PaginatedPaymentDto } from '../models/PaginatedPaymentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns CsvReportGenerationResult OK
     * @throws ApiError
     */
    public generatePaginatedPaymentsCsvReport({
        shopId,
        search = '',
        sortBy = '',
        direction = '',
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<CsvReportGenerationResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v2/payment/{shopId}/csv',
            path: {
                'shopId': shopId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaginatedPaymentDto OK
     * @throws ApiError
     */
    public getPaginatedPayments({
        shopId,
        search = '',
        sortBy = '',
        direction = '',
        from = '',
        to = '',
        page,
        limit = 20,
    }: {
        shopId: string,
        search?: string,
        sortBy?: string,
        direction?: string,
        from?: string,
        to?: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedPaymentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v2/payment/{shopId}',
            path: {
                'shopId': shopId,
            },
            query: {
                'search': search,
                'sortBy': sortBy,
                'direction': direction,
                'from': from,
                'to': to,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
